<template>
    <!-- eslint-disable -->
    <Form 
        :action="action" 
        :id="id"
        :dataProvinsiVal="dataProvinsi" 
        :dataKotaKabupatenVal="dataKotaKabupaten" 
        :dataKecamatanVal="dataKecamatan" 
        :dataKelurahanVal="dataKelurahan" 
        :dataPembiayaanVal="dataPembiayaan" 
        :dataDokterPengirimVal="dataDokterPengirim" 
        :dataJaringanDidapatVal="dataJaringanDidapat" 
        :dataJenisPemeriksaanVal="dataJenisPemeriksaan" 
        :dataLokasiAsalJaringanVal="dataLokasiAsalJaringan" 
        :dataRsKlinikVal="dataRsKlinik" 
        :dataSediaanVal="dataSediaan" 
        :dataDokterPemeriksaVal="dataDokterPemeriksa" 
        :dataFiksasiVal="dataFiksasi" 
        :dataLayananVal="dataLayanan" 
        :dataTopographyVal="dataTopography" 
        :dataMorphologyVal="dataMorphology" 
        :dataIcd10Val="dataIcd10" 
        :dataDerajatHistopatologiVal="dataDerajatHistopatologi" 
        :dataSuratIzinPraktekVal="dataSuratIzinPraktek" 

        :pasienVal="pasien" 
        :namaVal="nama" 
        :gelarKekeluargaanVal="gelarKekeluargaan" 
        :nikVal="nik" 
        :tanggalLahirVal="tanggalLahir" 
        :jenisKelaminVal="jenisKelamin" 
        :alamatVal="alamat"
        :kewarganegaraanVal="kewarganegaraan" 
        :kodePosVal="kodePos" 
        :hpTeleponVal="hpTelepon" 
        :emailVal="email" 
        :statusPernikahanVal="statusPernikahan" 
        :agamaVal="agama" 
        :beratBadanVal="beratBadan" 
        :tinggiBadanVal="tinggiBadan" 
        :provinsiVal="provinsi" 
        :kotaKabupatenVal="kotaKabupaten" 
        :kecamatanVal="kecamatan" 
        :kelurahanVal="kelurahan"
        :noBpjsVal="noBpjs"
        :umurTahunVal="umurTahun"
        :umurBulanVal="umurBulan"
        :umurHariVal="umurHari"

        :statusVal="status" 
        :noSediaanVal="noSediaan" 
        :noRekamMedisVal="noRekamMedis" 
        :isProcessingVal="isProcessing" 
        :tanggalDiterimaVal="tanggalDiterima" 
        :rsKlinikVal="rsKlinik" 
        :isBayarVal="isBayar" 
        :isTagihVal="isTagih" 
        :dokterPengirimVal="dokterPengirim" 
        :dokterPemeriksaVal="dokterPemeriksa" 
        :isFavoritVal="isFavorit" 
        :kodeJenisPemeriksaanVal="kodeJenisPemeriksaan" 
        :jenisPemeriksaanVal="jenisPemeriksaan" 
        :pembiayaanVal="pembiayaan" 
        :lokasiAsalJaringanVal="lokasiAsalJaringan" 
        :jaringanVal="jaringan" 
        :sediaanVal="sediaan" 
        :jumlahWadahVal="jumlahWadah" 
        :jumlahJaringanVal="jumlahJaringan" 
        :isSpesimenDiterimaVal="isSpesimenDiterima" 
        :keteranganKlinisVal="keteranganKlinis" 
        :diagnosaKlinisVal="diagnosaKlinis" 
        :riwayatLabPaVal="riwayatLabPa" 
        :waktuOperasiVal="waktuOperasi" 
        :waktuFiksasiVal="waktuFiksasi" 
        :makroskopikVal="makroskopik" 
        :mikroskopikVal="mikroskopik" 
        :topographyVal="topography" 
        :morphologyVal="morphology" 
        :icd10Val="icd10" 
        :derajatHistopatologiVal="derajatHistopatologi" 
        :tanggalDijawabVal="tanggalDijawab" 
        :isVolFiksasi10xVal="isVolFiksasi10x" 
        :kesimpulanVal="kesimpulan" 
        :emailPengirimVal="emailPengirim" 
        :emailPenerimaVal="emailPenerima" 
        :fiksasiVal="fiksasi" 
        :layananVal="layanan" 
        :suratIzinPraktekVal="suratIzinPraktek" 
        :uploadPengantarVal="uploadPengantar" 
        :catatanVal="catatan" 
        :anjuranVal="anjuran" 
        :fileTypePengantarVal="fileTypePengantar"

        :isKonsultasiVal="isKonsultasi"
        :catatanKonsultasiVal="catatanKonsultasi"
        :dokterKonsultasiVal="dokterKonsultasi"
     />
</template>

<script>
import Form from './Form.vue'
import {ref} from '@vue/composition-api'
var moment = require('moment');

export default {
    setup(props, context){
        const g = context.root

        const $http = g.$http

        var pasien = ref('')
        var nama = ref('')
        var noBpjs = ref('')
        var gelarKekeluargaan = ref('')
        var nik = ref('')
        var tanggalLahir = ref('')
        var jenisKelamin = ref('')
        var alamat = ref('')
        var kewarganegaraan = ref('')
        var kodePos = ref('')
        var hpTelepon = ref('')
        var email = ref('')
        var statusPernikahan = ref('')
        var agama = ref({text: '-- Pilih Opsi --', value: null})
        var beratBadan = ref('')
        var tinggiBadan = ref('')
        var provinsi = ref({text: '-- Pilih Opsi --'})
        var kotaKabupaten = ref({text: '-- Pilih Opsi --'})
        var kecamatan = ref({text: '-- Pilih Opsi --'})
        var kelurahan = ref({text: '-- Pilih Opsi --'})
        var umurTahun = ref('')
        var umurBulan = ref('')
        var umurHari = ref('')

        var noSediaan = ref('')
        var noRekamMedis = ref('')
        var isProcessing = ref([])
        var tanggalDiterima = ref(moment().format('DD-MM-YYYY hh:mm'))
        var rsKlinik = ref({text: '-- Pilih Opsi --', value: null})
        var dokterPengirim = ref({text: '-- Pilih Opsi --', value: null})
        var dokterPemeriksa = ref('')
        var isFavorit = ref('')
        var isBayar = ref([])
        var isTagih = ref([])
        var kodeJenisPemeriksaan = ref('')
        var layanan = ref('')
        var jenisPemeriksaan = ref('')
        var pembiayaan = ref({text: '-- Pilih Opsi --', value: null})
        var lokasiAsalJaringan = ref([])
        var jaringan = ref('')
        var sediaan = ref('')
        var jumlahWadah = ref('')
        var jumlahJaringan = ref('')
        var status = ref('')
        var isSpesimenDiterima = ref([])
        var keteranganKlinis = ref('')
        var diagnosaKlinis = ref('')
        var riwayatLabPa = ref('')
        var makroskopik = ref('')
        var mikroskopik = ref('')
        var fiksasi = ref({text: '-- Pilih Opsi --', value: null})
        var waktuOperasi = ref('')
        var waktuFiksasi = ref('')
        var topography = ref([])
        var morphology = ref([])
        var icd10 = ref([])
        var derajatHistopatologi = ref([])
        var tanggalDijawab = ref('')
        var isVolFiksasi10x = ref([])
        var kesimpulan = ref('')
        var emailPengirim = ref('')
        var emailPenerima = ref('')
        var suratIzinPraktek = ref('')
        var catatan = ref('')
        var anjuran = ref('')
        var fileTypePengantar = ref('')
        var uploadPengantar = ref('')
        var isKonsultasi = ref(false)
        var catatanKonsultasi = ref('')
        var dokterKonsultasi = ref(null)

        var dataProvinsi = ref([])
        var dataKotaKabupaten = ref([])
        var dataKecamatan = ref([])
        var dataKelurahan = ref([])
        var dataPembiayaan = ref([])
        var dataDokterPengirim = ref([])
        var dataDokterPemeriksa = ref([])
        var dataJaringanDidapat = ref([])
        var dataLayanan = ref([])
        var dataJenisPemeriksaan = ref([])
        var dataLokasiAsalJaringan = ref([])
        var dataRsKlinik = ref([])
        var dataSediaan = ref([])
        var dataFiksasi = ref([])
        var dataTopography = ref([])
        var dataMorphology = ref([])
        var dataIcd10 = ref([])
        var dataDerajatHistopatologi = ref([])
        var dataSuratIzinPraktek = ref([])

        const getData = () => {
            $http({
                url: '/pemeriksaan/pemeriksaan/ubah/' + props.id,
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    const {dataPesanan, namaVal, nikVal, gelarKekeluargaanVal, tanggalLahirVal, jenisKelaminVal, alamatVal, kewarganegaraanVal, kodePosVal, hpTeleponVal, emailVal, noSediaanVal, tanggalTerimaVal, rsKlinikVal, dokterPengirimVal, dokterPemeriksaVal, isFavoritVal, kodeJenisPemeriksaanVal, jenisPemeriksaanVal, pembiayaanVal, lokasiAsalJaringanVal, jaringanVal, sediaanVal, provinsiVal, kotaKabupatenVal, kecamatanVal, kelurahanVal, statusPernikahanVal, agamaVal, beratBadanVal, tinggiBadanVal, isProcessingVal, noRekamMedisVal, isBayarVal, isTagihVal, jumlahWadahVal, jumlahJaringanVal, statusVal, pasienVal, keteranganKlinisVal, diagnosaKlinisVal, riwayatLabPaVal, makroskopikVal, mikroskopikVal, fiksasiVal, waktuOperasiVal, waktuFiksasiVal, topographyVal, morphologyVal, derajatHistopatologiVal, icd10Val, tanggalDijawabVal, noBpjsVal, isVolFiksasi10xVal, kesimpulanVal, emailPengirimVal, emailPenerimaVal, umurTahunVal, umurBulanVal, umurHariVal, layananVal, suratIzinPraktekVal, uploadPengantarVal, catatanVal, anjuranVal, isKonsultasiVal, catatanKonsultasiVal, dokterKonsultasiVal} = res.data.data
                    const {dataProvinsiVal, dataKotaKabupatenVal, dataKecamatanVal, dataKelurahanVal, dataPembiayaanVal, dataDokterPengirimVal, dataJaringanDidapatVal, dataJenisPemeriksaanVal, dataLokasiAsalJaringanVal, dataRsKlinikVal, dataSediaanVal, dataDokterPemeriksaVal, dataFiksasiVal, dataLayananVal, dataTopographyVal, dataMorphologyVal, dataIcd10Val, dataDerajatHistopatologiVal, dataSuratIzinPraktekVal} = dataPesanan

                    pasien.value = pasienVal
                    nama.value = namaVal
                    gelarKekeluargaan.value = gelarKekeluargaanVal
                    nik.value = nikVal ? nikVal.toString() : nikVal != null ? nikVal.toString() : nikVal
                    tanggalLahir.value = tanggalLahirVal
                    jenisKelamin.value = jenisKelaminVal
                    alamat.value = alamatVal
                    kewarganegaraan.value = kewarganegaraanVal
                    kodePos.value = kodePosVal ? kodePosVal.toString() : kodePosVal != null ? kodePosVal.toString() : kodePosVal
                    hpTelepon.value = hpTeleponVal ? hpTeleponVal.toString() : hpTeleponVal != null ? hpTeleponVal.toString() : hpTeleponVal
                    email.value = emailVal
                    statusPernikahan.value = statusPernikahanVal
                    agama.value = agamaVal
                    beratBadan.value = beratBadanVal ? beratBadanVal.toString() : beratBadanVal != null ? beratBadanVal.toString() : beratBadanVal
                    tinggiBadan.value = tinggiBadanVal ? tinggiBadanVal.toString() : tinggiBadanVal != null ? tinggiBadanVal.toString() : tinggiBadanVal
                    provinsi.value = provinsiVal
                    kotaKabupaten.value = kotaKabupatenVal
                    kecamatan.value = kecamatanVal
                    kelurahan.value = kelurahanVal
                    noBpjs.value = noBpjsVal
                    umurTahun.value = umurTahunVal ? umurTahunVal.toString() : umurTahunVal == 0 ? '0' : umurTahunVal
                    umurBulan.value = umurBulanVal ? umurBulanVal.toString() : umurBulanVal == 0 ? '0' : umurBulanVal
                    umurHari.value = umurHariVal ? umurHariVal.toString() : umurHariVal == 0 ? '0' : umurHariVal

                    noSediaan.value = noSediaanVal
                    isProcessing.value = isProcessingVal ? [isProcessingVal] : []
                    isVolFiksasi10x.value = isVolFiksasi10xVal ? [isVolFiksasi10xVal] : []
                    isBayar.value = isBayarVal ? ['Bayar'] : []
                    isTagih.value = isTagihVal ? [true] : []
                    tanggalDiterima.value = tanggalTerimaVal
                    rsKlinik.value = rsKlinikVal
                    dokterPengirim.value = dokterPengirimVal
                    noRekamMedis.value = noRekamMedisVal
                    dokterPemeriksa.value = dokterPemeriksaVal
                    isFavorit.value = isFavoritVal
                    kodeJenisPemeriksaan.value = kodeJenisPemeriksaanVal
                    layanan.value = layananVal
                    jenisPemeriksaan.value = jenisPemeriksaanVal
                    pembiayaan.value = pembiayaanVal
                    lokasiAsalJaringan.value = lokasiAsalJaringanVal
                    jaringan.value = jaringanVal
                    sediaan.value = sediaanVal
                    jumlahWadah.value = jumlahWadahVal ? jumlahWadahVal.toString() : jumlahWadahVal != null ? jumlahWadahVal.toString() : jumlahWadahVal
                    jumlahJaringan.value = jumlahJaringanVal ? jumlahJaringanVal.toString() : jumlahJaringanVal != null ? jumlahJaringanVal.toString() : jumlahJaringanVal
                    status.value = statusVal
                    isSpesimenDiterima.value = statusVal == 'Draf' || statusVal == 'Batal' ? [] : ['Spesimen Diterima']
                    topography.value = topographyVal
                    keteranganKlinis.value = keteranganKlinisVal
                    diagnosaKlinis.value = diagnosaKlinisVal
                    riwayatLabPa.value = riwayatLabPaVal
                    makroskopik.value = makroskopikVal
                    mikroskopik.value = mikroskopikVal
                    fiksasi.value = fiksasiVal
                    waktuOperasi.value = waktuOperasiVal
                    waktuFiksasi.value = waktuFiksasiVal
                    morphology.value = morphologyVal
                    icd10.value = icd10Val
                    derajatHistopatologi.value = derajatHistopatologiVal
                    tanggalDijawab.value = tanggalDijawabVal
                    kesimpulan.value = kesimpulanVal
                    emailPengirim.value = emailPengirimVal
                    emailPenerima.value = emailPenerimaVal
                    suratIzinPraktek.value = suratIzinPraktekVal
                    uploadPengantar.value = uploadPengantarVal
                    catatan.value = catatanVal
                    anjuran.value = anjuranVal

                    if(uploadPengantarVal){
                        fileTypePengantar.value = uploadPengantarVal.split('.')
                        fileTypePengantar.value = fileTypePengantar.value[fileTypePengantar.value.length - 1]
                    }
                    else
                        fileTypePengantar.value = ''

                    dataProvinsi.value = dataProvinsiVal
                    dataKotaKabupaten.value = dataKotaKabupatenVal
                    dataKecamatan.value = dataKecamatanVal
                    dataKelurahan.value = dataKelurahanVal
                    dataPembiayaan.value = dataPembiayaanVal
                    dataDokterPengirim.value = dataDokterPengirimVal
                    dataDokterPemeriksa.value = dataDokterPemeriksaVal
                    dataJaringanDidapat.value = dataJaringanDidapatVal
                    dataLayanan.value = dataLayananVal
                    dataJenisPemeriksaan.value = dataJenisPemeriksaanVal
                    dataLokasiAsalJaringan.value = dataLokasiAsalJaringanVal
                    dataRsKlinik.value = dataRsKlinikVal
                    dataSediaan.value = dataSediaanVal
                    dataFiksasi.value = dataFiksasiVal
                    dataTopography.value = dataTopographyVal
                    dataMorphology.value = dataMorphologyVal
                    dataIcd10.value = dataIcd10Val
                    dataDerajatHistopatologi.value = dataDerajatHistopatologiVal
                    dataSuratIzinPraktek.value = dataSuratIzinPraktekVal

                    isKonsultasi.value = isKonsultasiVal
                    catatanKonsultasi.value = catatanKonsultasiVal
                    dokterKonsultasi.value = dokterKonsultasiVal
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Get Data`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        getData()
        return {
            nama, gelarKekeluargaan, nik, tanggalLahir, jenisKelamin, noSediaan, tanggalDiterima, rsKlinik, dokterPengirim, dokterPemeriksa, isFavorit, kodeJenisPemeriksaan, jenisPemeriksaan, pembiayaan, lokasiAsalJaringan, jaringan, sediaan, provinsi, kotaKabupaten, kecamatan, kelurahan, alamat, kewarganegaraan, kodePos, hpTelepon, email, statusPernikahan, agama, beratBadan, tinggiBadan, isProcessing, noRekamMedis, isBayar, isTagih, jumlahWadah, jumlahJaringan, status, isSpesimenDiterima, pasien, keteranganKlinis, diagnosaKlinis, riwayatLabPa, makroskopik, mikroskopik, fiksasi, waktuOperasi, waktuFiksasi, topography, morphology, derajatHistopatologi, icd10, tanggalDijawab, noBpjs, isVolFiksasi10x, kesimpulan, emailPengirim, emailPenerima, umurTahun, umurBulan, umurHari, layanan, suratIzinPraktek, uploadPengantar, catatan, anjuran, fileTypePengantar, isKonsultasi, catatanKonsultasi, dokterKonsultasi,

            dataProvinsi, dataKotaKabupaten, dataPembiayaan, dataDokterPengirim, dataJaringanDidapat, dataJenisPemeriksaan, dataLokasiAsalJaringan, dataRsKlinik, dataSediaan, dataDokterPemeriksa, dataKecamatan, dataKelurahan, dataFiksasi, dataLayanan, dataTopography, dataMorphology, dataIcd10, dataDerajatHistopatologi, dataSuratIzinPraktek
        }
    },
    data(){
        return{
            action: 'Ubah'
        }
    },
    components: {
        Form
    },
    props: ['id']
}
</script>