<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Cek Data</b-card-title>
        <b-card-title class="text-primary">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="btn-icon"
            size="sm"
            :to="{name: 'pemeriksaanPemeriksaan'}"
          >
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
        </b-card-title>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col
            md="10"
            offset-md="1"
            class="pl-0 pr-0"
          >
            <!-- input label -->
            <b-form-group
              label="Nama / NIK"
              label-for="namaNik"
            >
              <vue-autosuggest
                :suggestions="opsiNamaNik"
                v-model="namaNik"
                :limit="10"
                :input-props="{id:'namaNik',class:'form-control'}"
                @input="changeNamaNik"
                @selected="selectedNamaNik"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <form @submit.prevent="submitForm">

      <b-card title="Informasi Layanan">
        <b-row>
          <b-col
            md="10"
            offset-md="1"
            class="pl-0 pr-0"
          >
            <!-- no sediaan -->
            <b-form-group
              label="No. Lab PA"
              label-for="noSediaan"
              v-if="kodeSediaan"
            >
              <h4 class="text-primary font-weight-bolder">{{kodeSediaan}}</h4>
            </b-form-group>

            <!-- jenis pemeriksaan -->
            <b-row>
              <b-col
                align-self="center"
                :md="peran == 'Admin Rumah Sakit / Klinik' ? '12' : '9'"
              >
                <!-- input jenisPemeriksaan -->
                <b-form-group
                  :label="'Jenis Pemeriksaan'"
                  label-for="jenisPemeriksaan"
                >
                  <b-form-select
                    v-model="v$.jenisPemeriksaan.$model"
                    :options="dataJenisPemeriksaan"
                    :state="validateState(v$.jenisPemeriksaan)"
                    @change="changeJenisPemeriksaan"
                    :disabled="statusPesanan != 'Draf' && statusPesanan != 'Batal'"
                  />
                  <b-form-invalid-feedback
                    v-for="(error, index) of v$.jenisPemeriksaan.$errors"
                    :key="index"
                  >
                    {{ error.$message }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                align-self="center"
                v-if="peran != 'Admin Rumah Sakit / Klinik'"
              >
                <!-- input isSpesimenDiterima -->
                <b-form-checkbox
                  v-model="v$.isSpesimenDiterima.$model"
                  :state="validateState(v$.isSpesimenDiterima)"
                  value="Spesimen Diterima"
                  :disabled="statusPesanan != 'Draf' && statusPesanan != 'Batal'"
                > Spesimen Diterima?
                </b-form-checkbox>
              </b-col>
            </b-row>

            <!-- input tanggal diterima -->
            <b-form-group
              label="Tanggal Diterima"
              label-for="tanggalDiterima"
              v-if="isSpesimenDiterima.length > 0"
            >
              <flat-pickr
                id="tanggalDiterima"
                v-model="tanggalDiterima"
                class="form-control"
                :config="{ enableTime: true, dateFormat: 'd-m-Y H:i', time_24hr: true}"
              />
            </b-form-group>

            <!-- baris rs klinik -->
            <b-row>
              <b-col md="9">
                <!-- input RS Klinik -->
                <b-form-group
                  label="RS / Klinik"
                  label-for="rsKlinik"
                >
                  <v-select
                    id="rsKlinik"
                    v-model="rsKlinik"
                    label="text"
                    :options="dataRsKlinik"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                align-self="center"
              >
                <!-- input isProcessing -->
                <b-form-checkbox
                  v-model="v$.isProcessing.$model"
                  :state="validateState(v$.isProcessing)"
                  value="Processing"
                  :disabled="statusPesanan != 'Draf' && !$can('input', 'pemeriksaan-pemeriksaan-isProcessing')"
                > Titip Processing
                </b-form-checkbox>
              </b-col>
            </b-row>

            <!-- dokter pengirim -->
            <b-row>
              <b-col md="8">
                <!-- input dokterPengirim -->
                <b-form-group
                  label="Dokter Pengirim"
                  label-for="dokterPengirim"
                >
                  <v-select
                    id="dokterPengirim"
                    v-model="dokterPengirim"
                    label="text"
                    :options="dataDokterPengirim"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                align-self="center"
              >
                <!-- input isTagih -->
                <b-form-checkbox
                  v-model="isTagih"
                  value="true"
                  :disabled="!$can('input', 'pemeriksaan-pemeriksaan-isTagih')"
                > Telah Ditagih ?
                </b-form-checkbox>
              </b-col>
              <b-col
                md="2"
                align-self="center"
              >
                <!-- input isBayar -->
                <b-form-checkbox
                  v-model="isBayar"
                  value="Bayar"
                  :disabled="!$can('input', 'pemeriksaan-pemeriksaan-isBayar')"
                > Telah Dibayar ?
                </b-form-checkbox>
              </b-col>
            </b-row>

            <!-- input pembiayaan -->
            <b-form-group
              label="Pembiayaan"
              label-for="pembiayaan"
            >
              <v-select
                id="pembiayaan"
                v-model="v$.pembiayaan.$model"
                taggable
                label="text"
                :options="dataPembiayaan"
                :disabled="statusPesanan != 'Draf' && statusPesanan != 'Batal'"
              />
              <small
                class="text-danger"
                v-for="(error, index) of v$.pembiayaan.$errors"
                :key="index"
              >
                <span v-if="error.$validator == 'checkSelect'">Pembiayaan Tidak Boleh Kosong</span>
                <span v-else>{{ error.$message }}</span>
                <br>
              </small>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card title="Informasi Pasien">
        <b-row>
          <b-col
            md="10"
            offset-md="1"
            class="pl-0 pr-0"
          >
            <!-- input nama -->
            <b-form-group
              label="Nama"
              label-for="nama"
            >
              <b-row>
                <b-col md="2">
                  <b-form-select
                    v-model="v$.gelarKekeluargaan.$model"
                    :options="dataGelarKekeluargaan"
                    :state="validateState(v$.gelarKekeluargaan)"
                    @input="inputGelarKekeluargaan"
                  />
                </b-col>
                <b-col md="10">
                  <b-form-input
                    id="nama"
                    v-model="v$.nama.$model"
                    :state="validateState(v$.nama)"
                  />
                </b-col>
              </b-row>
              <small
                class="text-danger"
                v-for="(error, i) of v$.gelarKekeluargaan.$errors"
                :key="i"
              >{{ error.$message }}<br></small>
              <small
                class="text-danger"
                v-for="(error, j) of v$.nama.$errors"
                :key="j"
              >{{ error.$message }}</small>
            </b-form-group>

            <!-- baris nik -->
            <b-row>
              <b-col md="6">
                <!-- input nik -->
                <b-form-group
                  label="NIK"
                  label-for="nik"
                >
                  <b-form-input
                    id="nik"
                    v-model="nik"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!-- input noBpjs -->
                <b-form-group
                  label="No. BPJS"
                  label-for="noBpjs"
                >
                  <b-form-input
                    id="noBpjs"
                    v-model="noBpjs"
                    type="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- input noRekamMedis -->
            <b-form-group
              label="No. Rekam Medis"
              label-for="noRekamMedis"
            >
              <b-form-input
                id="noRekamMedis"
                v-model="v$.noRekamMedis.$model"
                @keydown="changeIntOnly"
              />
            </b-form-group>

            <!-- tanggal lahir -->
            <b-row>
              <b-col md="6">
                <!-- input tanggalLahir -->
                <b-form-group
                  label="Tanggal Lahir"
                  label-for="tanggalLahir"
                >

                  <b-input-group>
                    <b-form-input
                      id="tanggalLahir"
                      v-model="v$.tanggalLahir.$model"
                      type="text"
                      placeholder="DD-MM-YYYY"
                      autocomplete="off"
                      show-decade-nav
                      :state="validateState(v$.tanggalLahir)"
                      @input="changeTanggalLahir"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="tanggalLahir2"
                        show-decade-nav
                        button-only
                        right
                        locale="en"
                        aria-controls="tanggalLahir"
                        :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric', }"
                        :max="max"
                        @context="onContext"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    class="text-danger"
                    v-for="(error, index) of v$.tanggalLahir.$errors"
                    :key="index"
                  >
                    <span v-if="error.$validator == 'checkFormatDate'">Format Tanggal Tidak sesuai</span>
                    <br>
                  </small>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!-- input umur -->
                <b-form-group
                  label="Umur"
                  label-for="umur"
                >
                  <b-row>
                    <b-col md="4">
                      <b-form-input
                        id="umurTahun"
                        placeholder="Tahun"
                        v-model="v$.umurTahun.$model"
                        :state="validateState(v$.umurTahun)"
                        @keydown="changeIntOnly"
                      />
                    </b-col>
                    <b-col
                      md="4"
                      class="pl-0"
                    >
                      <b-form-input
                        id="umurBulan"
                        placeholder="Bulan"
                        v-model="umurBulan"
                        :state="validateState(v$.umurTahun)"
                        @keydown="changeIntOnly"
                      />
                    </b-col>
                    <b-col
                      md="4"
                      class="pl-0"
                    >
                      <b-form-input
                        id="umurHari"
                        placeholder="Hari"
                        v-model="umurHari"
                        :state="validateState(v$.umurTahun)"
                        @keydown="changeIntOnly"
                      />
                    </b-col>
                  </b-row>
                  <small
                    class="text-danger"
                    v-for="(error, i) of v$.umurTahun.$errors"
                    :key="i"
                  ><span v-if="error.$validator == 'checkRequiredUmur'">Umur Tidak Boleh Kosong</span></small>
                  <!-- <b-form-input
                                        id="umur"
                                        v-model="v$.umur.$model"
                                        :state="validateState(v$.umur)"
                                    />

                                    <b-form-invalid-feedback v-for="(error, index) of v$.umur.$errors" :key="index">
                                        {{ error.$message }}
                                    </b-form-invalid-feedback> -->
                </b-form-group>
              </b-col>
            </b-row>

            <!-- input jenisKelamin -->
            <b-form-group
              label="Jenis Kelamin"
              label-for="jenisKelamin"
            >
              <b-row class="pl-1">
                <b-form-radio
                  v-model="v$.jenisKelamin.$model"
                  value="Laki-laki"
                  :state="validateState(v$.jenisKelamin)"
                >
                  Laki-laki
                </b-form-radio>
                <b-form-radio
                  class="ml-1"
                  v-model="v$.jenisKelamin.$model"
                  value="Perempuan"
                  :state="validateState(v$.jenisKelamin)"
                >
                  Perempuan
                </b-form-radio>
              </b-row>
              <small
                class="text-danger"
                v-for="(error, index) of v$.jenisKelamin.$errors"
                :key="index"
              >
                {{ error.$message }}
              </small>
            </b-form-group>

            <!-- input alamat -->
            <b-form-group
              label="Alamat"
              label-for="alamat"
            >
              <b-form-textarea
                id="alamat"
                rows="3"
                :state="validateState(v$.alamat)"
                v-model="v$.alamat.$model"
              />
            </b-form-group>

            <b-row>
              <b-col md="6">
                <!-- input kewarganegaraan -->
                <b-form-group
                  label="Kewarganegaraan"
                  label-for="kewarganegaraan"
                >
                  <b-form-select
                    v-model="kewarganegaraan"
                    :options="dataKewarganegaraan"
                    id="kewarganegaraan"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!-- input provinsi -->
                <b-form-group
                  label="Provinsi"
                  label-for="provinsi"
                >
                  <v-select
                    id="provinsi"
                    v-model="v$.provinsi.$model"
                    taggable
                    label="text"
                    :options="dataProvinsi"
                    @input="inputProvinsi"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                md="6"
                v-if="dataKotaKabupaten.length > 1"
              >
                <!-- input kotaKabupaten -->
                <b-form-group
                  label="Kota / Kabupaten"
                  label-for="kotaKabupaten"
                >
                  <v-select
                    id="kotaKabupaten"
                    v-model="v$.kotaKabupaten.$model"
                    taggable
                    label="text"
                    :options="dataKotaKabupaten"
                    @input="inputKotaKabupaten"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                v-if="dataKecamatan.length > 1"
              >
                <!-- input kecamatan -->
                <b-form-group
                  label="Kecamatan"
                  label-for="kecamatan"
                >
                  <v-select
                    id="kecamatan"
                    v-model="kecamatan"
                    taggable
                    label="text"
                    :options="dataKecamatan"
                    @input="inputKecamatan"
                    @search="searchKecamatan"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                md="6"
                v-if="dataKelurahan.length > 1"
              >
                <!-- input kelurahan -->
                <b-form-group
                  label="Kelurahan"
                  label-for="kelurahan"
                >
                  <v-select
                    id="kelurahan"
                    v-model="kelurahan"
                    taggable
                    label="text"
                    :options="dataKelurahan"
                    @search="searchKelurahan"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!-- input kodePos -->
                <b-form-group
                  label="Kode Pos"
                  label-for="kodePos"
                >
                  <b-form-input
                    id="kodePos"
                    v-model="v$.kodePos.$model"
                    type="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- input hpTelepon -->
            <b-form-group
              label="HP / Telepon"
              label-for="hpTelepon"
            >
              <b-form-input
                id="hpTelepon"
                v-model="v$.hpTelepon.$model"
                type="number"
              />
            </b-form-group>

            <!-- input emailName -->
            <b-form-group
              label="Email"
              label-for="emailName"
            >
              <b-form-input
                type="email"
                id="emailName"
                :state="validateState(v$.emailName)"
                v-model="v$.emailName.$model"
              />
              <b-form-invalid-feedback
                v-for="(error, index) of v$.emailName.$errors"
                :key="index"
              >
                {{ error.$message }}
              </b-form-invalid-feedback>
            </b-form-group>

            <!-- input status -->
            <b-form-group
              label="Status"
              label-for="status"
            >
              <b-row class="pl-1">
                <b-form-radio
                  v-model="v$.status.$model"
                  value="Kawin"
                  :state="validateState(v$.status)"
                >
                  Kawin
                </b-form-radio>
                <b-form-radio
                  class="ml-1"
                  v-model="v$.status.$model"
                  value="Belum Kawin"
                  :state="validateState(v$.status)"
                >
                  Belum Kawin
                </b-form-radio>
                <b-form-radio
                  class="ml-1"
                  v-model="v$.status.$model"
                  value="Cerai Hidup"
                  :state="validateState(v$.status)"
                >
                  Cerai Hidup
                </b-form-radio>
                <b-form-radio
                  class="ml-1"
                  v-model="v$.status.$model"
                  value="Cerai Mati"
                  :state="validateState(v$.status)"
                >
                  Cerai Mati
                </b-form-radio>
              </b-row>
            </b-form-group>

            <!-- input agama -->
            <b-form-group
              label="Agama"
              label-for="agama"
            >
              <v-select
                id="agama"
                v-model="v$.agama.$model"
                taggable
                label="text"
                :options="dataAgama"
              />
            </b-form-group>

            <b-row>
              <b-col md="6">
                <!-- input beratBadan -->
                <b-form-group
                  label="Berat Badan (kg)"
                  label-for="beratBadan"
                >
                  <b-form-input
                    id="beratBadan"
                    v-model="v$.beratBadan.$model"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <!-- input tinggiBadan -->
                <b-form-group
                  label="Tinggi Badan (cm)"
                  label-for="tinggiBadan"
                >
                  <b-form-input
                    id="tinggiBadan"
                    v-model="v$.tinggiBadan.$model"
                    type="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>

      <b-card title="Informasi Spesimen">
        <!-- <b-card-header>
                    <b-card-title>Informasi Spesimen</b-card-title>
                    <b-card-title>
                        <h6>
                            <b-badge variant="primary" v-if="action == 'Ubah'">
                                {{statusPesanan}}
                            </b-badge>
                            <b-button
                                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                variant="flat-danger"
                                class="btn-icon rounded-circle"
                                @click="clickFavorit"
                                v-if="statusPesanan == 'Diagnosa'"
                            >
                                <feather-icon icon="StarIcon" :style="isFillIcon ? 'fill: #ea5455' : ''" size="24" />
                            </b-button>
                        </h6>
                    </b-card-title>
                </b-card-header> -->

        <b-col
          md="12"
          class="pl-0 pr-0"
        >
          <b-row>
            <b-col
              md="10"
              offset-md="1"
              class="pl-0 pr-0"
            >
              <!-- baris jaringan -->
              <b-row>
                <b-col md="6">
                  <!-- input lokasiAsalJaringan -->
                  <b-form-group
                    label="Lokasi Asal Jaringan Yang Sakit"
                    label-for="lokasiAsalJaringan"
                  >
                    <v-select
                      id="lokasiAsalJaringan"
                      v-model="lokasiAsalJaringan"
                      label="text"
                      :options="dataLokasiAsalJaringan"
                      multiple
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <!-- input jaringan -->
                  <b-form-group
                    label="Jaringan Didapatkan Dengan:"
                    label-for="jaringan"
                  >
                    <b-form-select
                      v-model="v$.jaringan.$model"
                      :options="dataJaringanDidapat"
                      :state="validateState(v$.jaringan)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- baris sediaan -->
              <b-row>
                <b-col md="6">
                  <!-- input sediaan -->
                  <b-form-group
                    label="Sediaan Spesimen"
                    label-for="sediaan"
                  >
                    <b-form-select
                      v-model="v$.sediaan.$model"
                      :options="dataSediaan"
                      :state="validateState(v$.sediaan)"
                    />
                    <b-form-invalid-feedback
                      v-for="(error, index) of v$.sediaan.$errors"
                      :key="index"
                    >
                      {{ error.$message }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <!-- input jumlahWadah -->
                  <b-form-group
                    label="Jumlah Wadah"
                    label-for="jumlahWadah"
                  >
                    <b-form-input
                      id="jumlahWadah"
                      v-model="jumlahWadah"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <!-- input jumlahJaringan -->
                  <b-form-group
                    label="Jumlah Jaringan"
                    label-for="jumlahJaringan"
                  >
                    <b-form-input
                      id="jumlahJaringan"
                      type="number"
                      v-model="jumlahJaringan"
                      @keydown="changeIntOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- baris fiksasi -->
              <b-row>
                <b-col md="8">
                  <!-- input fiksasi -->
                  <b-form-group
                    label="Fiksasi"
                    label-for="fiksasi"
                  >
                    <v-select
                      id="fiksasi"
                      v-model="fiksasi"
                      taggable
                      label="text"
                      :options="dataFiksasi"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="4"
                  align-self="center"
                >
                  <!-- input isVolFiksasi10x -->
                  <b-form-checkbox
                    v-model="isVolFiksasi10x"
                    value="true"
                  > Vol Fiksasi 10x Vol Spesimen ?
                  </b-form-checkbox>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Waktu Mulai Operasi"
                    label-for="waktuOperasi"
                  >
                    <flat-pickr
                      id="waktuOperasi"
                      v-model="waktuOperasi"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'd-m-Y H:i', time_24hr: true}"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <!-- input waktuFiksasi -->
                  <b-form-group
                    label="Waktu Mulai Fiksasi"
                    label-for="waktuFiksasi"
                  >
                    <flat-pickr
                      id="waktuFiksasi"
                      v-model="waktuFiksasi"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'd-m-Y H:i', time_24hr: true}"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

      </b-card>

      <!-- card informasi klinis -->
      <b-card title="Informasi Klinis">
        <b-col
          md="12"
          class="pl-0 pr-0"
        >
          <b-row>
            <b-col
              md="12"
              class="pl-0 pr-0"
            >
              <!-- keteranganKlinis -->
              <b-row>
                <b-col
                  md="10"
                  offset-md="1"
                >
                  <!-- input keteranganKlinis -->
                  <b-form-group
                    label="Keterangan Klinis"
                    label-for="keteranganKlinis"
                  >
                    <quill-editor
                      v-model="v$.keteranganKlinis.$model"
                      :options="editorOptionKeteranganKlinis"
                    >
                      <div
                        id="keteranganKlinis"
                        slot="toolbar"
                      >
                        <!-- Add a bold button -->
                        <button class="ql-bold">
                          Bold
                        </button>
                        <button class="ql-italic">
                          Italic
                        </button>

                        <select class="ql-header">
                          <option value="1" />
                          <option value="2" />
                          <option value="3" />
                          <option value="4" />
                          <option value="5" />
                          <option value="6" />
                        </select>

                        <!-- Add subscript and superscript buttons -->
                        <button
                          class="ql-script"
                          value="sub"
                        />
                        <button
                          class="ql-script"
                          value="super"
                        />
                        <button
                          class="ql-list"
                          value="ordered"
                        />
                        <button
                          class="ql-list"
                          value="bullet"
                        />
                        <!-- <button
                                                    class="ql-image"
                                                /> -->
                      </div>
                    </quill-editor>
                    <small
                      class="text-danger"
                      v-for="(error, index) of v$.keteranganKlinis.$errors"
                      :key="index"
                    >
                      <span v-if="error.$validator == 'checkDatabase'">Keterangan Klinis Tidak Boleh Kosong</span>
                      <span v-else>{{ error.$message }}</span>
                    </small>
                  </b-form-group>
                </b-col>
              </b-row>

              <hr>

              <!-- diagnosaKlinis -->
              <b-row>
                <b-col
                  md="10"
                  offset-md="1"
                >
                  <!-- input diagnosaKlinis -->
                  <b-form-group
                    label="Diagnosa Klinis"
                    label-for="diagnosaKlinis"
                  >
                    <quill-editor
                      v-model="v$.diagnosaKlinis.$model"
                      :options="editorOptionDiagnosaKlinis"
                    >
                      <div
                        id="diagnosaKlinis"
                        slot="toolbar"
                      >
                        <!-- Add a bold button -->
                        <button class="ql-bold">
                          Bold
                        </button>
                        <button class="ql-italic">
                          Italic
                        </button>

                        <select class="ql-header">
                          <option value="1" />
                          <option value="2" />
                          <option value="3" />
                          <option value="4" />
                          <option value="5" />
                          <option value="6" />
                        </select>

                        <!-- Add subscript and superscript buttons -->
                        <button
                          class="ql-script"
                          value="sub"
                        />
                        <button
                          class="ql-script"
                          value="super"
                        />
                        <button
                          class="ql-list"
                          value="ordered"
                        />
                        <button
                          class="ql-list"
                          value="bullet"
                        />
                        <!-- <button
                                                    class="ql-image"
                                                /> -->
                      </div>
                    </quill-editor>
                    <small
                      class="text-danger"
                      v-for="(error, index) of v$.diagnosaKlinis.$errors"
                      :key="index"
                    >
                      <span v-if="error.$validator == 'checkDatabase'">Diagnosa Klinis Tidak Boleh Kosong</span>
                      <span v-else>{{ error.$message }}</span>
                    </small>
                  </b-form-group>
                </b-col>
              </b-row>

              <hr>

              <!-- input icd10 -->
              <b-row>
                <b-col
                  md="10"
                  offset-md="1"
                >
                  <b-form-group
                    label="ICD-10"
                    label-for="icd10"
                  >
                    <v-select
                      id="icd10"
                      v-model="icd10"
                      label="text"
                      :options="dataIcd10"
                      multiple
                      @search="searchIcd10"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <hr>

              <!-- riwayatLabPa -->
              <b-row>
                <b-col
                  md="10"
                  offset-md="1"
                >
                  <!-- input riwayatLabPa -->
                  <b-form-group
                    label="Riwayat Lab PA"
                    label-for="riwayatLabPa"
                  >
                    <quill-editor
                      v-model="v$.riwayatLabPa.$model"
                      :options="editorOptionRiwayatLabPa"
                    >
                      <div
                        id="riwayatLabPa"
                        slot="toolbar"
                      >
                        <!-- Add a bold button -->
                        <button class="ql-bold">
                          Bold
                        </button>
                        <button class="ql-italic">
                          Italic
                        </button>

                        <select class="ql-header">
                          <option value="1" />
                          <option value="2" />
                          <option value="3" />
                          <option value="4" />
                          <option value="5" />
                          <option value="6" />
                        </select>

                        <!-- Add subscript and superscript buttons -->
                        <button
                          class="ql-script"
                          value="sub"
                        />
                        <button
                          class="ql-script"
                          value="super"
                        />
                        <button
                          class="ql-list"
                          value="ordered"
                        />
                        <button
                          class="ql-list"
                          value="bullet"
                        />

                        <!-- <button
                                                    class="ql-image"
                                                /> -->
                      </div>
                    </quill-editor>
                    <small
                      class="text-danger"
                      v-for="(error, index) of v$.riwayatLabPa.$errors"
                      :key="index"
                    >
                      <span v-if="error.$validator == 'checkDatabase'">Riwayat Lab PA Tidak Boleh Kosong</span>
                      <span v-else>{{ error.$message }}</span>
                    </small>
                  </b-form-group>
                </b-col>
              </b-row>

              <hr>

              <b-row>
                <b-col
                  md="10"
                  offset-md="1"
                >
                  <!-- input uploadPengantar -->
                  <b-form-group
                    label="Upload Pengantar"
                    label-for="uploadPengantar"
                  >
                    <b-row>
                      <b-col md="10">
                        <b-form-file
                          id="uploadGambar"
                          v-model="v$.uploadPengantar.$model"
                          :state="validateState(v$.uploadPengantar)"
                          accept="image/*,application/pdf"
                          @change="changeUploadPengantar"
                        />
                      </b-col>
                      <b-col md="2">
                        <b-button
                          variant="info"
                          class="btn-icon mr-1"
                          size="sm"
                          @click="showUploadPengantar()"
                          :disabled="!(gambarUploadPengantar || pdfUploadPengantar)"
                        >
                          <feather-icon :icon="gambarUploadPengantar || pdfUploadPengantar ? 'EyeIcon' : 'EyeOffIcon'" />
                        </b-button>
                        <b-button
                          variant="danger"
                          class="btn-icon"
                          size="sm"
                          @click="hapusUploadPengantar()"
                          :disabled="false"
                        >
                          <feather-icon icon="Trash2Icon" />
                        </b-button>
                      </b-col>
                    </b-row>

                    <small
                      class="text-danger"
                      v-for="(error, index) of v$.uploadPengantar.$errors"
                      :key="index"
                    >
                      <span v-if="error.$validator == 'checkSizeUploadPengantar'">Ukuran File Tidak Boleh Lebih Dari 100KB</span>
                      <span v-else>{{ error.$message }}</span>
                    </small>
                  </b-form-group>

                  <div
                    id="pengantarPdf"
                    class="mb-2"
                    v-if="isShowUploadPdf"
                  ></div>
                </b-col>
              </b-row>

              <div v-if="statusPesanan != 'Diagnosa' && $can('input', 'pemeriksaan-pemeriksaan-dokterPemeriksa')">
                <hr>

                <b-row>
                  <b-col
                    md="10"
                    offset-md="1"
                  >
                    <!-- input dokterPemeriksa -->
                    <b-form-group
                      label="Dokter Pemeriksa"
                      label-for="dokterPemeriksa"
                      v-if="peran != 'Admin Rumah Sakit / Klinik'"
                    >
                      <b-form-select
                        v-model="v$.dokterPemeriksa.$model"
                        :options="dataDokterPemeriksa"
                        :state="validateState(v$.dokterPemeriksa)"
                      />
                      <b-form-invalid-feedback
                        v-for="(error, index) of v$.dokterPemeriksa.$errors"
                        :key="index"
                      >
                        <span v-if="error.$validator == 'checkDokterPemeriksa'">Dokter Pemeriksa Tidak Boleh Kosong</span>
                        <span v-else>{{ error.$message }}</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-col>

            <!-- semua button -->
            <b-col
              md="10"
              offset-md="1"
              class="pl-0 pr-0"
              v-if="statusPesanan != 'Diagnosa' && statusPesanan != 'Selesai'"
            >
              <b-col
                cols="12"
                class="text-right pr-0"
              >
                <div v-if="statusPesanan != 'Selesai'">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-info"
                    class="mr-1"
                    @click="generatePDF(id)"
                    v-if="statusPesanan == 'Processing' && $can('read', 'pemeriksaan-pemeriksaan-generatePdf')"
                  >
                    Pratinjau PDF
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    class="mr-1"
                    @click="resetForm"
                  >
                    Reset
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    v-if="statusPesanan == 'Draf' && $can('save', 'pemeriksaan-pemeriksaan-draf')"
                  >
                    <div v-if="isSubmit">
                      <b-spinner small />
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div v-else>Simpan</div>
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    v-if="statusPesanan == 'Register' && $can('save', 'pemeriksaan-pemeriksaan-register')"
                  >
                    <div v-if="isSubmit">
                      <b-spinner small />
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div v-else>Simpan</div>
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    v-if="statusPesanan == 'Processing' && $can('save', 'pemeriksaan-pemeriksaan-processing')"
                  >
                    <div v-if="isSubmit">
                      <b-spinner small />
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div v-else>Simpan</div>
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="primary"
                    class="ml-1"
                    @click="submitProses"
                    v-if="(statusPesanan == 'Processing' || statusPesanan == 'Susul' || statusPesanan == 'PLD' || statusPesanan == 'Konfirmasi') && $can('update', 'pemeriksaan-pemeriksaan-processing')"
                  >
                    <div v-if="isSubmit">
                      <b-spinner small />
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div v-else>Diagnosa</div>
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="info"
                    class="ml-1"
                    @click="submitProses"
                    v-if="statusPesanan == 'Register' && $can('update', 'pemeriksaan-pemeriksaan-register')"
                  >
                    <div v-if="isSubmit">
                      <b-spinner small />
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div v-else>Processing</div>
                  </b-button>
                </div>
              </b-col>
            </b-col>
          </b-row>
        </b-col>
      </b-card>

      <!-- card informasi Diagnostik -->
      <b-card
        no-body
        v-if="statusPesanan == 'Diagnosa' || statusPesanan == 'Selesai'"
      >
        <b-card-header>
          <b-card-title>Informasi Diagnostik</b-card-title>
          <b-card-title>
            <h6>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle mr-1"
                @click="clickKonsultasi"
                v-if="statusPesanan == 'Diagnosa' || statusPesanan == 'Selesai'"
                v-b-tooltip.hover
                title="Konsultasi"
              >
                <feather-icon
                  icon="UsersIcon"
                  :style="isKonsultasi ? 'fill: #ea5455' : ''"
                  size="24"
                />
              </b-button>
              <b-badge variant="primary">
                {{statusPesanan}}
              </b-badge>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle ml-1"
                @click="clickFavorit"
                v-if="statusPesanan == 'Diagnosa' || statusPesanan == 'Selesai'"
              >
                <feather-icon
                  icon="StarIcon"
                  :style="isFillIcon ? 'fill: #ea5455' : ''"
                  size="24"
                />
              </b-button>
            </h6>
          </b-card-title>
        </b-card-header>
        <b-card-body>
          <b-col
            md="12"
            class="pl-0 pr-0"
          >
            <b-row>
              <b-col
                md="12"
                class="pl-0 pr-0"
              >
                <!-- makroskopik -->
                <b-row>
                  <b-col
                    md="10"
                    offset-md="1"
                  >
                    <!-- input opsiMakroskopik -->
                    <b-form-group
                      label="Database Makroskopik"
                      label-for="opsiMakroskopik"
                    >
                      <b-form-input
                        id="opsiMakroskopik"
                        v-model="opsiMakroskopik"
                        @input="inputOpsiMakroskopik"
                      />

                      <b-list-group v-if="dataMakroskopik.length > 0">
                        <b-list-group-item
                          button
                          class="flex-column align-items-start"
                          v-for="(item, index) of dataMakroskopik"
                          :key="index"
                          @click="clickOpsiMakroskopik(item)"
                        >
                          <small
                            class="text-secondary"
                            v-html="item.text"
                          >
                          </small>
                        </b-list-group-item>
                      </b-list-group>
                    </b-form-group>

                    <!-- input makroskopik -->
                    <b-form-group
                      label="Makroskopik"
                      label-for="makroskopik"
                    >
                      <quill-editor
                        v-model="v$.makroskopik.$model"
                        :options="editorOptionMakroskopik"
                      >
                        <div
                          id="makroskopik"
                          slot="toolbar"
                        >
                          <!-- Add a bold button -->
                          <button class="ql-bold">
                            Bold
                          </button>
                          <button class="ql-italic">
                            Italic
                          </button>

                          <select class="ql-header">
                            <option value="1" />
                            <option value="2" />
                            <option value="3" />
                            <option value="4" />
                            <option value="5" />
                            <option value="6" />
                          </select>

                          <!-- Add subscript and superscript buttons -->
                          <button
                            class="ql-script"
                            value="sub"
                          />
                          <button
                            class="ql-script"
                            value="super"
                          />
                          <button
                            class="ql-list"
                            value="ordered"
                          />
                          <button
                            class="ql-list"
                            value="bullet"
                          />

                          <!-- <button
                                                        class="ql-image"
                                                    /> -->
                        </div>
                      </quill-editor>
                      <small
                        class="text-danger"
                        v-for="(error, index) of v$.makroskopik.$errors"
                        :key="index"
                      >
                        <span v-if="error.$validator == 'checkDatabase2'">Makroskopik Tidak Boleh Kosong</span>
                        <span v-else>{{ error.$message }}</span>
                      </small>
                    </b-form-group>
                  </b-col>
                </b-row>

                <hr>

                <!-- mikroskopik -->
                <b-row>
                  <b-col
                    md="10"
                    offset-md="1"
                  >
                    <!-- input opsiMikroskopik -->
                    <b-form-group
                      label="Database Mikroskopik"
                      label-for="opsiMikroskopik"
                    >
                      <b-form-input
                        id="opsiMikroskopik"
                        v-model="opsiMikroskopik"
                        @input="inputOpsiMikroskopik"
                      />

                      <b-list-group v-if="dataMikroskopik.length > 0">
                        <b-list-group-item
                          button
                          class="flex-column align-items-start"
                          v-for="(item, index) of dataMikroskopik"
                          :key="index"
                          @click="clickOpsiMikroskopik(item)"
                        >
                          <small
                            class="text-secondary"
                            v-html="item.text"
                          ></small>
                        </b-list-group-item>
                      </b-list-group>
                    </b-form-group>

                    <!-- input mikroskopik -->
                    <b-form-group
                      label="Mikroskopik"
                      label-for="mikroskopik"
                    >
                      <quill-editor
                        v-model="v$.mikroskopik.$model"
                        :options="editorOptionMikroskopik"
                      >
                        <div
                          id="mikroskopik"
                          slot="toolbar"
                        >
                          <!-- Add a bold button -->
                          <button class="ql-bold">
                            Bold
                          </button>
                          <button class="ql-italic">
                            Italic
                          </button>

                          <select class="ql-header">
                            <option value="1" />
                            <option value="2" />
                            <option value="3" />
                            <option value="4" />
                            <option value="5" />
                            <option value="6" />
                          </select>

                          <!-- Add subscript and superscript buttons -->
                          <button
                            class="ql-script"
                            value="sub"
                          />
                          <button
                            class="ql-script"
                            value="super"
                          />
                          <button
                            class="ql-list"
                            value="ordered"
                          />
                          <button
                            class="ql-list"
                            value="bullet"
                          />

                          <!-- <button
                                                        class="ql-image"
                                                    /> -->
                        </div>
                      </quill-editor>
                      <small
                        class="text-danger"
                        v-for="(error, index) of v$.mikroskopik.$errors"
                        :key="index"
                      >
                        <span v-if="error.$validator == 'checkDatabase2'">Mikroskopik Tidak Boleh Kosong</span>
                        <span v-else>{{ error.$message }}</span>
                      </small>
                    </b-form-group>
                  </b-col>
                </b-row>

                <hr>

                <!-- kesimpulan -->
                <b-row>
                  <b-col
                    md="10"
                    offset-md="1"
                  >
                    <!-- input kesimpulan -->
                    <b-form-group
                      label="Kesimpulan"
                      label-for="kesimpulan"
                    >
                      <quill-editor
                        v-model="v$.kesimpulan.$model"
                        :options="editorOptionKesimpulan"
                      >
                        <div
                          id="kesimpulan"
                          slot="toolbar"
                        >
                          <!-- Add a bold button -->
                          <button class="ql-bold ql-active">
                            Bold
                          </button>
                          <button class="ql-italic">
                            Italic
                          </button>

                          <select class="ql-header">
                            <option value="1" />
                            <option value="2" />
                            <option value="3" />
                            <option value="4" />
                            <option value="5" />
                            <option value="6" />
                          </select>

                          <!-- Add subscript and superscript buttons -->
                          <button
                            class="ql-script"
                            value="sub"
                          />
                          <button
                            class="ql-script"
                            value="super"
                          />
                          <button
                            class="ql-list"
                            value="ordered"
                          />
                          <button
                            class="ql-list"
                            value="bullet"
                          />

                          <!-- <button
                                                        class="ql-image"
                                                    /> -->
                        </div>
                      </quill-editor>
                      <small
                        class="text-danger"
                        v-for="(error, index) of v$.kesimpulan.$errors"
                        :key="index"
                      >
                        <span v-if="error.$validator == 'checkDatabase'">Kesimpulan Tidak Boleh Kosong</span>
                        <span v-else>{{ error.$message }}</span>
                      </small>
                    </b-form-group>
                  </b-col>
                </b-row>

                <hr>

                <!-- input derajatHistopatologi -->
                <!-- <b-row>
                                    <b-col md="10" offset-md="1">
                                        <b-form-group
                                            label="Derajat Histopatologi"
                                            label-for="derajatHistopatologi"
                                        >
                                            <v-select
                                                id="derajatHistopatologi"
                                                v-model="derajatHistopatologi"
                                                label="text"
                                                :options="dataDerajatHistopatologi"
                                                multiple
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row> -->

                <!-- topography -->
                <b-row>
                  <b-col
                    md="10"
                    offset-md="1"
                  >
                    <!-- input topography -->
                    <b-form-group
                      label="Topography"
                      label-for="topography"
                    >
                      <v-select
                        id="topography"
                        v-model="topography"
                        label="text"
                        :options="dataTopography"
                        multiple
                      />
                      <small
                        class="text-danger"
                        v-for="(error, index) of v$.topography.$errors"
                        :key="index"
                      >
                        <span v-if="error.$validator == 'checkDatabase'">Topography Tidak Boleh Kosong</span>
                        <span v-else>{{ error.$message }}</span>
                      </small>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- morphology -->
                <b-row>
                  <b-col
                    md="10"
                    offset-md="1"
                  >
                    <!-- input morphology -->
                    <b-form-group
                      label="Morphology"
                      label-for="morphology"
                    >
                      <v-select
                        id="morphology"
                        v-model="morphology"
                        label="text"
                        :options="dataMorphology"
                        multiple
                      />
                      <small
                        class="text-danger"
                        v-for="(error, index) of v$.morphology.$errors"
                        :key="index"
                      >
                        <span v-if="error.$validator == 'checkDatabase3'">Morphology Tidak Boleh Kosong</span>
                        <span v-else>{{ error.$message }}</span>
                      </small>
                    </b-form-group>
                  </b-col>
                </b-row>

                <hr>

                <!-- catatan -->
                <b-row>
                  <b-col
                    md="10"
                    offset-md="1"
                  >
                    <!-- input catatan -->
                    <b-form-group
                      label="Catatan"
                      label-for="catatan"
                    >
                      <quill-editor
                        v-model="catatan"
                        :options="editorOptionCatatan"
                      >
                        <div
                          id="catatan"
                          slot="toolbar"
                        >
                          <!-- Add a bold button -->
                          <button class="ql-bold">
                            Bold
                          </button>
                          <button class="ql-italic">
                            Italic
                          </button>

                          <select class="ql-header">
                            <option value="1" />
                            <option value="2" />
                            <option value="3" />
                            <option value="4" />
                            <option value="5" />
                            <option value="6" />
                          </select>

                          <!-- Add subscript and superscript buttons -->
                          <button
                            class="ql-script"
                            value="sub"
                          />
                          <button
                            class="ql-script"
                            value="super"
                          />
                          <button
                            class="ql-list"
                            value="ordered"
                          />
                          <button
                            class="ql-list"
                            value="bullet"
                          />

                          <!-- <button
                                                        class="ql-image"
                                                    /> -->
                        </div>
                      </quill-editor>
                      <small
                        class="text-danger"
                        v-for="(error, index) of v$.riwayatLabPa.$errors"
                        :key="index"
                      >
                        <span v-if="error.$validator == 'checkDatabase'">Riwayat Lab PA Tidak Boleh Kosong</span>
                        <span v-else>{{ error.$message }}</span>
                      </small>
                    </b-form-group>
                  </b-col>
                </b-row>

                <hr>

                <!-- anjuran -->
                <b-row>
                  <b-col
                    md="10"
                    offset-md="1"
                  >
                    <!-- input anjuran -->
                    <b-form-group
                      label="Anjuran"
                      label-for="anjuran"
                    >
                      <quill-editor
                        v-model="anjuran"
                        :options="editorOptionAnjuran"
                      >
                        <div
                          id="anjuran"
                          slot="toolbar"
                        >
                          <!-- Add a bold button -->
                          <button class="ql-bold">
                            Bold
                          </button>
                          <button class="ql-italic">
                            Italic
                          </button>

                          <select class="ql-header">
                            <option value="1" />
                            <option value="2" />
                            <option value="3" />
                            <option value="4" />
                            <option value="5" />
                            <option value="6" />
                          </select>

                          <!-- Add subscript and superscript buttons -->
                          <button
                            class="ql-script"
                            value="sub"
                          />
                          <button
                            class="ql-script"
                            value="super"
                          />
                          <button
                            class="ql-list"
                            value="ordered"
                          />
                          <button
                            class="ql-list"
                            value="bullet"
                          />

                          <!-- <button
                                                        class="ql-image"
                                                    /> -->
                        </div>
                      </quill-editor>
                      <small
                        class="text-danger"
                        v-for="(error, index) of v$.riwayatLabPa.$errors"
                        :key="index"
                      >
                        <span v-if="error.$validator == 'checkDatabase'">Riwayat Lab PA Tidak Boleh Kosong</span>
                        <span v-else>{{ error.$message }}</span>
                      </small>
                    </b-form-group>

                    <!-- input tanggalDijawab -->
                    <b-form-group
                      label="Tanggal Dijawab"
                      label-for="tanggalDijawab"
                    >
                      <b-input-group>
                        <b-form-input
                          id="tanggalDijawab"
                          v-model="v$.tanggalDijawab.$model"
                          type="text"
                          placeholder="DD-MM-YYYY"
                          autocomplete="off"
                          show-decade-nav
                          :state="validateState(v$.tanggalDijawab)"
                          @input="changeTanggalDijawab"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="tanggalDijawab2"
                            show-decade-nav
                            button-only
                            right
                            locale="en"
                            aria-controls="tanggalDijawab"
                            :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric', }"
                            :max="max"
                            @context="onContextTanggalDijawab"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small
                        class="text-danger"
                        v-for="(error, index) of v$.tanggalDijawab.$errors"
                        :key="index"
                      >
                        <span v-if="error.$validator == 'checkFormatDate'">Format Tanggal Tidak sesuai</span>
                        <span v-else-if="error.$validator == 'checkDatabase3'">Tanggal Dijawab Tidak Boleh Kosong</span>
                        <span v-else>{{ error.$message }}</span>
                        <br>
                      </small>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row v-if="$can('input', 'pemeriksaan-pemeriksaan-suratIzinPraktek')">
                  <b-col
                    md="10"
                    offset-md="1"
                  >
                    <!-- input suratIzinPraktek -->
                    <b-form-group
                      label="Surat Izin Praktek"
                      label-for="suratIzinPraktek"
                    >
                      <b-form-select
                        id="suratIzinPraktek"
                        v-model="v$.suratIzinPraktek.$model"
                        :options="dataSuratIzinPraktek"
                        :state="validateState(v$.suratIzinPraktek)"
                      />
                      <b-form-invalid-feedback
                        v-for="(error, index) of v$.suratIzinPraktek.$errors"
                        :key="index"
                      >
                        <span v-if="error.$validator == 'checkSuratIzinPraktek'">Surat Izin Praktek Tidak Boleh Kosong</span>
                        <span v-else>{{ error.$message }}</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row v-if="$can('input', 'pemeriksaan-pemeriksaan-dokterPemeriksa')">
                  <b-col
                    md="10"
                    offset-md="1"
                  >
                    <!-- input dokterPemeriksa -->
                    <b-form-group
                      label="Dokter Pemeriksa"
                      label-for="dokterPemeriksa"
                    >
                      <b-form-select
                        id="dokterPemeriksa"
                        v-model="v$.dokterPemeriksa.$model"
                        :options="dataDokterPemeriksa"
                        :state="validateState(v$.dokterPemeriksa)"
                      />
                      <b-form-invalid-feedback
                        v-for="(error, index) of v$.dokterPemeriksa.$errors"
                        :key="index"
                      >
                        <span v-if="error.$validator == 'checkDokterPemeriksa'">Dokter Pemeriksa Tidak Boleh Kosong</span>
                        <span v-else>{{ error.$message }}</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

              <!-- semua button -->
              <b-col
                md="10"
                offset-md="1"
                class="pl-0 pr-0"
              >
                <b-col
                  cols="12"
                  class="text-right pr-0"
                >
                  <div>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="button"
                      variant="outline-info"
                      class="mr-1"
                      @click="generatePDF(id)"
                      v-if="$can('read', 'pemeriksaan-pemeriksaan-generatePdf')"
                    >
                      Pratinjau PDF
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="button"
                      variant="outline-secondary"
                      class="mr-1"
                      @click="resetForm"
                      v-if="statusPesanan != 'Selesai'"
                    >
                      Reset
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      v-if="($can('save', 'pemeriksaan-pemeriksaan-diagnosa') && statusPesanan != 'Selesai') || $can('save-admin', 'pemeriksaan-pemeriksaan-diagnosa')"
                    >
                      <div v-if="isSubmit">
                        <b-spinner small />
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div v-else>Simpan</div>
                    </b-button>

                    <b-dropdown
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      right
                      variant="info"
                      text="Processing"
                      class="ml-1"
                      v-if="$can('update', 'pemeriksaan-pemeriksaan-processing-status') && statusPesanan != 'Selesai'"
                    >
                      <b-dropdown-item @click="clickToRegister('Susul')">Susul</b-dropdown-item>
                      <b-dropdown-item @click="clickToRegister('PLD')">PLD</b-dropdown-item>
                      <b-dropdown-item @click="clickToRegister('Konfirmasi')">Konfirmasi</b-dropdown-item>
                    </b-dropdown>

                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="button"
                      variant="info"
                      class="ml-1"
                      @click="submitProses"
                      v-if="$can('update', 'pemeriksaan-pemeriksaan-diagnosa') && statusPesanan != 'Selesai'"
                    >
                      <div v-if="isSubmit">
                        <b-spinner small />
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div v-else>Selesai</div>
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="button"
                      variant="primary"
                      class="ml-1"
                      @click="submit('Diagnosa')"
                      v-if="statusPesanan == 'Selesai' && $can('update', 'pemeriksaan-pemeriksaan-status')"
                    >
                      <div v-if="isSubmit">
                        <b-spinner small />
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div v-else>Diagnosa</div>
                    </b-button>
                  </div>
                </b-col>
              </b-col>
            </b-row>
          </b-col>
        </b-card-body>
      </b-card>
    </form>

    <GenerateHasil ref="generateHasil" />

    <!-- card email -->
    <b-card
      title="Kirim Email"
      v-if="(statusPesanan == 'Processing' || statusPesanan == 'Diagnosa') && $can('send-email', 'pemeriksaan-pemeriksaan')"
    >
      <b-col
        md="12"
        class="pl-0 pr-0"
      >
        <b-form
          class="needs-validation"
          @submit.prevent="submitFormEmail"
        >
          <b-row>
            <b-col
              md="10"
              offset-md="1"
              class="pl-0 pr-0"
            >

              <!-- input emailPenerima -->
              <b-form-group
                label="Penerima"
                label-for="emailPenerima"
              >
                <b-form-input
                  v-model="emailPenerima"
                  disabled
                  :state="isErrorEmailPenerima"
                />
                <small
                  v-if="isErrorEmailPenerima == false"
                  class="text-danger"
                >Penerima Tidak Ditemukan</small>
              </b-form-group>

              <!-- input emailSubject -->
              <b-form-group
                label="Subject"
                label-for="emailSubject"
              >
                <b-form-input
                  v-model="emailSubject"
                  :state="isErrorEmailSubject"
                />
                <small
                  v-if="isErrorEmailSubject == false"
                  class="text-danger"
                >Subject Tidak Ditemukan</small>
              </b-form-group>

              <!-- input emailPesan -->
              <b-form-group
                label="Pesan"
                label-for="emailPesan"
              >
                <b-form-textarea
                  rows="3"
                  v-model="emailPesan"
                  :state="isErrorEmailPesan"
                />
                <small
                  v-if="isErrorEmailPesan == false"
                  class="text-danger"
                >Pesan Tidak Ditemukan</small>
              </b-form-group>

              <b-col
                cols="12"
                class="text-right pr-0"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                >
                  <div v-if="isSubmitEmail">
                    <b-spinner small />
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div v-else>Kirim</div>
                </b-button>
              </b-col>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-card>

    <!-- modal toRegister-->
    <b-modal
      cancel-variant="outline-secondary"
      centered
      :title="'Pindahkan Ke ' + backStatus"
      ref="modal-register"
    >
      <b-form>
        <b-form-group>
          <label for="catatanProcessing">Catatan</label>
          <b-form-textarea
            id="catatanProcessing"
            rows="3"
            v-model="catatanProcessing"
          />
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right"
            @click="clickMoveToRegister"
          >
            Simpan
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- modal konsultasi-->
    <b-modal
      cancel-variant="outline-secondary"
      centered
      title="Konsultasi"
      ref="modal-konsultasi"
    >
      <b-form>
        <b-form-group>
          <label for="catatanKonsultasi">Catatan</label>
          <b-form-textarea
            id="catatanKonsultasi"
            rows="3"
            v-model="catatanKonsultasi"
          />
        </b-form-group>
      </b-form>
      <b-form-group>
        <label for="dokter">Dokter</label>
        <b-form-select
          id="dokter"
          v-model="dokterKonsultasi"
          :options="dataDokterKonsultasi"
          :state="isErrorKonsultasiDokter"
        />
        <small
          v-if="isErrorKonsultasiDokter == false"
          class="text-danger"
        >Dokter Pemeriksa Tidak Boleh Kosong</small>
      </b-form-group>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right"
            @click="clickMoveToKonsultasi"
          >
            Simpan
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      v-model="isShowModalGambar"
      hide-footer
      centered
      size="xl"
      title="Gambar"
    >
      <b-card-text>
        <b-img
          class="mx-auto"
          :src="gambarModal"
          fluid
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
    VBTooltip,
    BCard, BForm, BFormRow, BCol, BFormInput, BFormValidFeedback, BFormInvalidFeedback, BCardHeader, BCardBody, BButton, BFormGroup, BCardTitle, BSpinner, BFormSelect, BFormRadio, BRow, BFormTextarea, BListGroup, BListGroupItem, BFormDatepicker, BFormCheckbox, BInputGroup, BInputGroupAppend, BBadge, BDropdown, BDropdownItem, BFormFile, BOverlay, BImgLazy, BCardText, BImg
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { required, helpers, email } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import {ref, computed, watchEffect, watch, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { VueAutosuggest } from 'vue-autosuggest'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import vSelect from 'vue-select'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
var moment = require('moment');
import { jsPDF } from "jspdf";
import autotable from "jspdf-autotable";
const qr = require('qrcode');
import {decrypt} from '@/libs/crypto'
import helper from '@/libs/helper'
import pdfobject from "pdfobject";
import GenerateHasil from '@/views/pemeriksaan/pemeriksaan/GenerateHasil.vue'

export default {
    components: {
      GenerateHasil,
      flatPickr,
      quillEditor, vSelect, BCardCode, VueAutosuggest, BCard, BForm, BFormRow, BCol, BFormInput, BFormValidFeedback, BFormInvalidFeedback, BCardHeader, BCardBody, BButton, BFormGroup, BCardTitle, BSpinner, BFormSelect, BFormRadio, BRow, BFormTextarea, BListGroup, BListGroupItem, BFormDatepicker, BFormCheckbox, BInputGroup, BInputGroupAppend, BBadge, BDropdown, BDropdownItem, BFormFile, BOverlay, BImgLazy, BCardText, BImg
    },
    props: {
        action: String,
        id: String,
        namaVal: String,
        pasienVal: String,
        gelarKekeluargaanVal: String,
        nikVal: String,
        tanggalLahirVal: String,
        jenisKelaminVal: String,
        alamatVal: String,
        kewarganegaraanVal: String,
        kodePosVal: String,
        hpTeleponVal: String,
        emailVal: String,
        statusPernikahanVal: String,
        agamaVal: Object,
        beratBadanVal: String,
        tinggiBadanVal: String,
        kotaKabupatenVal: Object,
        kecamatanVal: Object,
        kelurahanVal: Object,
        provinsiVal: Object,
        noBpjsVal: String,
        umurTahunVal: String,
        umurBulanVal: String,
        umurHariVal: String,

        noSediaanVal: String,
        isProcessingVal: Array,
        tanggalDiterimaVal: String,
        rsKlinikVal: Object,
        dokterPengirimVal: Object,
        dokterPemeriksaVal: String,
        isFavoritVal: Boolean,
        noRekamMedisVal: String,
        isBayarVal: Array,
        isTagihVal: Array,
        kodeJenisPemeriksaanVal: String,
        jenisPemeriksaanVal: String,
        pembiayaanVal: Object,
        lokasiAsalJaringanVal: Array,
        jaringanVal: String,
        sediaanVal: String,
        jumlahWadahVal: String,
        jumlahJaringanVal: String,
        statusVal: String,
        isSpesimenDiterimaVal: Array,
        keteranganKlinisVal: String,
        diagnosaKlinisVal: String,
        riwayatLabPaVal: String,
        makroskopikVal: String,
        mikroskopikVal: String,
        fiksasiVal: Object,
        waktuOperasiVal: String,
        waktuFiksasiVal: String,
        topographyVal: Array,
        morphologyVal: Array,
        icd10Val: Array,
        derajatHistopatologiVal: Array,
        tanggalDijawabVal: String,
        isVolFiksasi10xVal: Array,
        kesimpulanVal: String,
        emailPenerimaVal: String,
        suratIzinPraktekVal: String,
        uploadPengantarVal: String,
        catatanVal: String,
        anjuranVal: String,
        fileTypePengantarVal: String,

        isKonsultasiVal: Boolean,
        catatanKonsultasiVal: String,
        dokterKonsultasiVal: String,
        
        dataProvinsiVal: Array,
        dataKotaKabupatenVal: Array,
        dataKecamatanVal: Array,
        dataKelurahanVal: Array,
        dataPembiayaanVal: Array,
        dataDokterPengirimVal: Array,
        dataJaringanDidapatVal: Array,
        dataJenisPemeriksaanVal: Array,
        dataLokasiAsalJaringanVal: Array,
        dataRsKlinikVal: Array,
        dataSediaanVal: Array,
        dataDokterPemeriksaVal: Array,
        dataFiksasiVal: Array,
        dataIcd10Val: Array,
        dataTopographyVal: Array,
        dataMorphologyVal: Array,
        dataDerajatHistopatologiVal: Array,
        dataSuratIzinPraktekVal: Array,
    },
    setup(props, context){
        const g = context.root

        var isSubmit = ref(false)
        var isSubmitEmail = ref(false)
        const router = g.$router
        const $http = g.$http
        const can = g.$can

        var now = moment()

        //form name
        var pasien = ref(null)
        var gelarKekeluargaan = ref(null)
        var nama = ref('')
        var nik = ref('')
        var noBpjs = ref('')
        var tanggalLahir = ref('')
        var tanggalLahir2 = ref('')
        var jenisKelamin = ref('')
        var alamat = ref('')
        var kewarganegaraan = ref('WNI')
        var provinsi = ref({text: '-- Pilih Opsi --', value: null})
        var kotaKabupaten = ref({text: '-- Pilih Opsi --', value: null})
        var kecamatan = ref({text: '-- Pilih Opsi --', value: null})
        var kelurahan = ref({text: '-- Pilih Opsi --', value: null})
        var kodePos = ref('')
        var hpTelepon = ref('')
        var emailName = ref('')
        var status = ref('')
        var agama = ref({text: '-- Pilih Opsi --', value: null})
        var beratBadan = ref('')
        var tinggiBadan = ref('')
        var umurHari = ref('')
        var umurBulan = ref('')
        var umurTahun = ref('')
        
        var jenisPemeriksaan = ref(null)
        var kodeJenisPemeriksaan = ref(null)
        var noSediaan = ref('0001')
        var rsKlinik = ref({text: '-- Pilih Opsi --', value: null})
        var dokterPengirim = ref(null)
        var noRekamMedis = ref('')
        var pembiayaan = ref({text: '-- Pilih Opsi --', value: null})
        var isBayar = ref([])
        var isTagih = ref([])
        var isProcessing = ref([])
        var lokasiAsalJaringan = ref([])
        var jaringan = ref(null)
        var sediaan = ref(null)
        var jumlahWadah = ref('')
        var jumlahJaringan = ref('')
        var dokterPemeriksa = ref(null)
        var opsiTopography = ref('')
        var opsiKeteranganKlinis = ref('')
        var opsiDiagnosaKlinis = ref('')
        var opsiRiwayatLabPa = ref('')
        var opsiMikroskopik = ref('')
        var opsiMakroskopik = ref('')
        var opsiMorphology = ref('')
        var topography = ref('')
        var keteranganKlinis = ref('')
        var diagnosaKlinis = ref('')
        var riwayatLabPa = ref('')
        var statusPesanan = ref('Draf')
        var isSpesimenDiterima = ref([])
        var tanggalDiterima = ref('')
        var makroskopik = ref('')
        var mikroskopik = ref('')
        var waktuOperasi = ref('')
        var waktuFiksasi = ref('')
        var fiksasi = ref('')
        var isVolFiksasi10x = ref([])
        var derajatHistopatologi = ref('')
        var morphology = ref('')
        var icd10 = ref([])
        var tanggalDijawab = ref('')
        var tanggalDijawab2 = ref('')
        var kesimpulan = ref('')
        var suratIzinPraktek = ref('')
        var uploadPengantar = ref([])
        var uploadPengantarName = ref('')
        var isDeleteUploadPengantar = ref(false)
        var catatan = ref('')
        var anjuran = ref('')
        var isKonsultasi = ref(false)

        var catatanKonsultasi = ref('')
        var dokterKonsultasi = ref(null)

        var catatanProcessing = ref('')

        var opsiNamaNik = ref([])
        var dataGelarKekeluargaan = ref([
            {
                text: '-- Pilih Opsi --',
                value: null
            },
            {
                text: 'Tn',
                value: 'Tn'
            },
            {
                text: 'Ny',
                value: 'Ny'
            },
            {
                text: 'Nn',
                value: 'Nn'
            },
            {
                text: 'An',
                value: 'An'
            }
        ])
        var dataAgama = ref([
            {
                text: '-- Pilih Opsi --',
                value: null
            },
            {
                text: 'Islam',
                value: 'Islam'
            },
            {
                text: 'Protestan',
                value: 'Protestan'
            },
            {
                text: 'Katolik',
                value: 'Katolik'
            },
            {
                text: 'Hindu',
                value: 'Hindu'
            },
            {
                text: 'Buddha',
                value: 'Buddha'
            }
        ])
        var dataKewarganegaraan = ref([
            {
                text: '-- Pilih Opsi --',
                value: null
            },
            {
                text: 'WNI',
                value: 'WNI'
            },
            {
                text: 'WNA',
                value: 'WNA'
            },
        ])
        var dataProvinsi = ref([])
        var dataKotaKabupaten = ref([])
        var dataKecamatan = ref([])
        var dataKelurahan = ref([])
        var dataJenisPemeriksaan = ref([])
        var dataRsKlinik = ref([])
        var dataDokterPengirim = ref([])
        var dataDokterPemeriksa = ref([])
        var dataLokasiAsalJaringan = ref([])
        var dataJaringanDidapat = ref([])
        var dataSediaan = ref([])
        var dataKeteranganKlinis = ref([])
        var dataDiagnosaKlinis = ref([])
        var dataRiwayatLabPa = ref([])
        var dataPembiayaan = ref([])
        var dataMikroskopik = ref([])
        var dataMakroskopik = ref([])
        var dataFiksasi = ref([])
        var dataTopography = ref([])
        var dataMorphology = ref([])
        var dataIcd10 = ref([])
        var dataDerajatHistopatologi = ref([])
        var dataSuratIzinPraktek = ref([])

        var dataDokterKonsultasi = ref([])

        let max = moment().format('YYYY-MM-DD')

        var namaNik = ref('')
        var peran = ref(g.$cookies.get('peran'))
        var isFillIcon = ref(false)
        var isErrorAktif = ref(false)
        var backStatus = ref('')
        var gambarUploadPengantar = ref('')
        var pdfUploadPengantar = ref('')
        var isShowUploadPdf = ref(false)
        var isShowModalGambar = ref(false)
        var gambarModal = ref('')
        var fileTypePengantar = ref('')

        var emailPengirim = ref('')
        var emailPenerima = ref('')
        var emailSubject = ref('')
        var emailPesan = ref('')
        var isErrorEmailPenerima = ref(null)
        var isErrorEmailSubject = ref(null)
        var isErrorEmailPesan = ref(null)
        var isErrorKonsultasiDokter = ref(null)
        var isAktifValidasi = ref(true)

        var pJenisPemeriksaan = ref('')
        var pNoSediaan = ref('')
        var pNik = ref('')
        var pNama = ref('')
        var pNoBpjs = ref('')
        var pUmurTglLahir = ref('')
        var pNoRekamMedis = ref('')
        var pJenisKelamin = ref('')
        var pAlamat = ref('')
        var pTanggalDiterima = ref('')
        var pJamOperasi = ref('')
        var pTanggalDijawab = ref('')
        var pJamFiksasi = ref('')
        var pSediaan = ref('')
        var pJumlahJaringan = ref('')
        var pLokasiAsalJaringan = ref('')
        var pFiksasi = ref('')
        var pTopography = ref('')
        var pIsVolFiksasi10x = ref('')
        var pDiagnosaKlinis = ref('')
        var pKeteranganKlinis = ref('')
        var pRiwayatLabPa = ref('')
        var pMakroskopik = ref('')
        var pMikroskopik = ref('')
        var pKesimpulan = ref('')
        var pDerajatHistopatologi = ref('')
        var pMorphology = ref('')
        var pIcdO = ref('')
        var pDokterPemeriksa = ref('')
        var pCatatan = ref('')
        var pAnjuran = ref('')
        var labelPdf1 = ref('Jam Mulai Operasi')
        var labelPdf2 = ref('Jam Mulai Fiksasi')
        var labelPdf3 = ref('Jumlah Wadah / Jaringan yang Diterima')
        var labelPdf4 = ref('Fiksasi yang Digunakan')
        var valuePdf1 = ref('')
        var valuePdf2 = ref('')
        var valuePdf3 = ref('')
        var valuePdf4 = ref('')
        
        watchEffect(() => {
            pasien.value = props.pasienVal
            gelarKekeluargaan.value = props.gelarKekeluargaanVal
            nama.value = props.namaVal
            nik.value = props.nikVal
            tanggalLahir.value = props.tanggalLahirVal
            tanggalLahir2.value = props.tanggalLahirVal
            jenisKelamin.value = props.jenisKelaminVal
            alamat.value = props.alamatVal
            kewarganegaraan.value = props.kewarganegaraanVal
            kodePos.value = props.kodePosVal
            hpTelepon.value = props.hpTeleponVal
            emailName.value = props.emailVal
            status.value = props.statusPernikahanVal
            agama.value = props.agamaVal
            beratBadan.value = props.beratBadanVal
            tinggiBadan.value = props.tinggiBadanVal
            provinsi.value = props.provinsiVal
            kotaKabupaten.value = props.kotaKabupatenVal
            kecamatan.value = props.kecamatanVal
            kelurahan.value = props.kelurahanVal
            noBpjs.value = props.noBpjsVal
            umurTahun.value = props.umurTahunVal
            umurBulan.value = props.umurBulanVal
            umurHari.value = props.umurHariVal

            jenisPemeriksaan.value = props.jenisPemeriksaanVal
            noSediaan.value = props.noSediaanVal
            isProcessing.value = props.isProcessingVal
            isFillIcon.value = props.isFavoritVal
            rsKlinik.value = props.rsKlinikVal
            dokterPengirim.value = props.dokterPengirimVal
            dokterPemeriksa.value = props.dokterPemeriksaVal
            isBayar.value = props.isBayarVal
            isTagih.value = props.isTagihVal
            noRekamMedis.value = props.noRekamMedisVal
            jumlahWadah.value = props.jumlahWadahVal
            jumlahJaringan.value = props.jumlahJaringanVal
            statusPesanan.value = props.statusVal
            isSpesimenDiterima.value = props.isSpesimenDiterimaVal
            tanggalDiterima.value = props.tanggalDiterimaVal
            kodeJenisPemeriksaan.value = props.kodeJenisPemeriksaanVal
            pembiayaan.value = props.pembiayaanVal
            lokasiAsalJaringan.value = props.lokasiAsalJaringanVal
            jaringan.value = props.jaringanVal
            sediaan.value = props.sediaanVal
            keteranganKlinis.value = props.keteranganKlinisVal
            diagnosaKlinis.value = props.diagnosaKlinisVal
            riwayatLabPa.value = props.riwayatLabPaVal
            makroskopik.value = props.makroskopikVal
            mikroskopik.value = props.mikroskopikVal
            fiksasi.value = props.fiksasiVal
            waktuOperasi.value = props.waktuOperasiVal
            waktuFiksasi.value = props.waktuFiksasiVal
            topography.value = props.topographyVal
            morphology.value = props.morphologyVal
            icd10.value = props.icd10Val
            derajatHistopatologi.value = props.derajatHistopatologiVal
            isVolFiksasi10x.value = props.isVolFiksasi10xVal
            tanggalDijawab.value = props.tanggalDijawabVal
            tanggalDijawab2.value = props.tanggalDijawabVal
            kesimpulan.value = props.kesimpulanVal
            emailPengirim.value = props.emailPengirimVal
            emailPenerima.value = props.emailPenerimaVal
            suratIzinPraktek.value = props.suratIzinPraktekVal
            gambarUploadPengantar.value = props.uploadPengantarVal
            uploadPengantarName.value = props.uploadPengantarVal
            pdfUploadPengantar.value = props.uploadPengantarVal
            catatan.value = props.catatanVal
            anjuran.value = props.anjuranVal
            fileTypePengantar.value = props.fileTypePengantarVal

            isKonsultasi.value = props.isKonsultasiVal
            catatanKonsultasi.value = props.catatanKonsultasiVal
            dokterKonsultasi.value = props.dokterKonsultasiVal

            dataProvinsi.value = props.dataProvinsiVal
            dataKotaKabupaten.value = props.dataKotaKabupatenVal
            dataKecamatan.value = props.dataKecamatanVal
            dataKelurahan.value = props.dataKelurahanVal
            dataPembiayaan.value = props.dataPembiayaanVal
            dataDokterPengirim.value = props.dataDokterPengirimVal
            dataDokterPemeriksa.value = props.dataDokterPemeriksaVal
            dataJaringanDidapat.value = props.dataJaringanDidapatVal
            dataJenisPemeriksaan.value = props.dataJenisPemeriksaanVal
            dataLokasiAsalJaringan.value = props.dataLokasiAsalJaringanVal
            dataRsKlinik.value = props.dataRsKlinikVal
            dataSediaan.value = props.dataSediaanVal
            dataFiksasi.value = props.dataFiksasiVal
            dataIcd10.value = props.dataIcd10Val
            dataTopography.value = props.dataTopographyVal
            dataMorphology.value = props.dataMorphologyVal
            dataDerajatHistopatologi.value = props.dataDerajatHistopatologiVal
            dataSuratIzinPraktek.value = props.dataSuratIzinPraktekVal
        })

        const checkFormatDate = (val) => {
            if(typeof val !== 'undefined' && val != ''){
                let tanggal = moment(val, 'DD-MM-YYYY', true).format('DD-MM-YYYY');
                
                return tanggal == 'Invalid date' ? false : true;
            }
            return true
        }

        const checkSelect = (val) => {
            if(!val.value){
                if(val.text == '-- Pilih Opsi --')
                    return false
            }
            return true
        }

        const checkDatabase = (val) => {
            if(statusPesanan.value == 'Diagnosa'){
                if(isErrorAktif.value){
                    if(typeof val === 'undefined' || val == '')
                        return false
                }
            }

            return true
        }

        const checkDokterPemeriksa = (val) => {
            if(peran.value != 'Admin Rumah Sakit / Klinik'){
                if(typeof val === 'undefined' || val == '' || val == null)
                    return false
            }

            return true
        }

        const checkSizeUploadPengantar = (val) => {
            if(val.size > 100000)
                return false
            return true
        }

        const checkSuratIzinPraktek = (val) => {
            if(statusPesanan.value == 'Diagnosa' && can('input', 'pemeriksaan-pemeriksaan-suratIzinPraktek')){
                if(typeof val === 'undefined' || val == '' || val == null)
                    return false;
            }
            return true
        }

        const checkRequiredUmur = (val) => {
            if((typeof val === 'undefined' || val == '' || val == null) && (typeof umurBulan.value === 'undefined' || umurBulan.value == '' || umurBulan.value == null) && (typeof umurHari.value === 'undefined' || umurHari.value == '' || umurHari.value == null))
                return false;
            
            return true
        }

        // validation
        const requiredMsg = 'tidak boleh kosong'
        const $externalResults = ref({})
        const rules = computed(() => ({
            gelarKekeluargaan: {
                required: helpers.withMessage('Data '+requiredMsg, required)
            },
            nama: {
                required: helpers.withMessage('Nama '+requiredMsg, required)
            },
            jenisKelamin: {
                required: helpers.withMessage('Jenis Kelamin '+requiredMsg, required)
            },
            tanggalLahir: {
                checkFormatDate
            },
            alamat: {},
            provinsi: {},
            kotaKabupaten: {},
            kodePos: {},
            hpTelepon: {},
            emailName: {
                email: helpers.withMessage('Format Email Salah', email)
            },
            status: {},
            agama: {},
            beratBadan: {},
            tinggiBadan: {},
            umurTahun: {
                checkRequiredUmur
                // required: helpers.withMessage('Umur '+requiredMsg, required)
            },
            uploadPengantar: {
                checkSizeUploadPengantar
            },

            jenisPemeriksaan: {
                required: helpers.withMessage('Jenis Pemeriksaan '+requiredMsg, required)
            },
            noRekamMedis: {},
            pembiayaan: {
                checkSelect
            },
            jaringan: {
                // required: helpers.withMessage('Jaringan Yang Didapat '+requiredMsg, required)
            },
            sediaan: {
                required: helpers.withMessage('Sediaan Spesimen '+requiredMsg, required)
            },
            dokterPemeriksa: {
                checkDokterPemeriksa
            },
            isProcessing: {},
            isSpesimenDiterima: {},
            tanggalDiterima: {},
            topography: {
                // checkDatabase
            },
            keteranganKlinis: {
                // checkDatabase
            },
            diagnosaKlinis: {
                // checkDatabase
            },
            riwayatLabPa: {
                // checkDatabase
            },
            makroskopik: {
                // checkDatabase
            },
            mikroskopik: {
                // checkDatabase
            },
            morphology: {
                // checkDatabase
            },
            tanggalDijawab: {
                checkDatabase,
                checkFormatDate
            },
            kesimpulan: {
                checkDatabase
            },
            suratIzinPraktek: {
                checkSuratIzinPraktek
            }
        }))

        const v$ = useVuelidate(rules, { gelarKekeluargaan, nama, tanggalLahir, jenisKelamin, alamat, provinsi, kotaKabupaten, kodePos, hpTelepon, emailName, status, agama, beratBadan, tinggiBadan, keteranganKlinis, diagnosaKlinis, riwayatLabPa, jenisPemeriksaan, noRekamMedis, pembiayaan, jaringan, sediaan, dokterPemeriksa, isProcessing, isSpesimenDiterima, tanggalDiterima, makroskopik, mikroskopik, topography, morphology, tanggalDijawab, kesimpulan, umurTahun, uploadPengantar, suratIzinPraktek}, {$externalResults, $autoDirty: true})

        //computed
        const kodeSediaan = computed(() => {
            let kodeSediaanTemp = ''
            if(kodeJenisPemeriksaan.value && isSpesimenDiterima.value.length > 0){
                if(isNaN(noSediaan.value))
                    changeJenisPemeriksaan()
                    
                kodeSediaanTemp =  `${kodeJenisPemeriksaan.value}.${noSediaan.value}.${moment(tanggalDiterima.value, 'DD-MM-YYYY HH:mm').format('YY')}`;
            }
            
            return kodeSediaanTemp;
        })

        const submit = async (setStatus = null) => {
            isSubmit.value = true;
            const isFormCorrect = await v$.value.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            //jika error validasi

            if (!isFormCorrect && isAktifValidasi.value) {
                isSubmit.value = false
                return
            }

            let fd = new FormData()
            fd.append('pasien', pasien.value)
            fd.append('gelarKekeluargaan', gelarKekeluargaan.value)
            fd.append('nama', nama.value)
            if(nik.value)
                fd.append('nik', nik.value)
            if(noBpjs.value)
                fd.append('noBpjs', noBpjs.value)
            if(tanggalLahir.value)
                fd.append('tanggalLahir', tanggalLahir.value)
            
            fd.append('jenisKelamin', jenisKelamin.value)
            if(alamat.value)
                fd.append('alamat', alamat.value)
            if(kewarganegaraan.value)
                fd.append('kewarganegaraan', kewarganegaraan.value)
            if(provinsi.value)
                fd.append('provinsi', JSON.stringify(provinsi.value))
            if(kotaKabupaten.value)
                fd.append('kotaKabupaten', JSON.stringify(kotaKabupaten.value))
            if(kecamatan.value)
                fd.append('kecamatan', JSON.stringify(kecamatan.value))
            if(kelurahan.value)
                fd.append('kelurahan', JSON.stringify(kelurahan.value))
            if(kodePos.value)
                fd.append('kodePos', kodePos.value)
            if(hpTelepon.value)
                fd.append('hpTelepon', hpTelepon.value)
            if(emailName.value)
                fd.append('emailName', emailName.value)
            if(status.value)
                fd.append('status', status.value)
            if(agama.value)
                fd.append('agama', JSON.stringify(agama.value))
            if(beratBadan.value)
                fd.append('beratBadan', beratBadan.value)
            if(tinggiBadan.value)
                fd.append('tinggiBadan', tinggiBadan.value)
            // fd.append('umur', umur.value)
            fd.append('umurTahun', umurTahun.value)
            fd.append('umurBulan', umurBulan.value)
            fd.append('umurHari', umurHari.value)

            if(noSediaan.value)
                fd.append('noSediaan', noSediaan.value)
            fd.append('jenisPemeriksaan', jenisPemeriksaan.value)
            if(rsKlinik.value)
                fd.append('rsKlinik', rsKlinik.value.value)
            if(tanggalDiterima.value)
                fd.append('tanggalDiterima', tanggalDiterima.value)
            if(dokterPengirim.value)
                fd.append('dokterPengirim', dokterPengirim.value.value)
            if(dokterPemeriksa.value)
                fd.append('dokterPemeriksa', dokterPemeriksa.value)
            if(noRekamMedis.value)
                fd.append('noRekamMedis', noRekamMedis.value)
            fd.append('pembiayaan', JSON.stringify(pembiayaan.value))
            if(isBayar.value)
                fd.append('isBayar', isBayar.value)
            if(isTagih.value)
                fd.append('isTagih', isTagih.value)
            if(isProcessing.value)
                fd.append('isProcessing', isProcessing.value)
            if(isSpesimenDiterima.value)
                fd.append('isSpesimenDiterima', JSON.stringify(isSpesimenDiterima.value))
            if(isFillIcon.value)
                fd.append('isFavorit', isFillIcon.value)
            if(lokasiAsalJaringan.value)
                fd.append('lokasiAsalJaringan', JSON.stringify(lokasiAsalJaringan.value))
            if(jaringan.value)
                fd.append('jaringan', jaringan.value)
            if(sediaan.value)
                fd.append('sediaan', sediaan.value)
            if(jumlahWadah.value)
                fd.append('jumlahWadah', jumlahWadah.value)
            if(jumlahJaringan.value)
                fd.append('jumlahJaringan', jumlahJaringan.value)
            if(keteranganKlinis.value)
                fd.append('keteranganKlinis', keteranganKlinis.value)
            if(diagnosaKlinis.value)
                fd.append('diagnosaKlinis', diagnosaKlinis.value)
            if(riwayatLabPa.value)
                fd.append('riwayatLabPa', riwayatLabPa.value)
            if(makroskopik.value)
                fd.append('makroskopik', makroskopik.value)
            if(mikroskopik.value)
                fd.append('mikroskopik', mikroskopik.value)
            if(fiksasi.value)
                fd.append('fiksasi', JSON.stringify(fiksasi.value))
            if(waktuOperasi.value)
                fd.append('waktuOperasi', waktuOperasi.value)
            if(waktuFiksasi.value)
                fd.append('waktuFiksasi', waktuFiksasi.value)
            if(isVolFiksasi10x.value)
                fd.append('isVolFiksasi10x', isVolFiksasi10x.value)
            if(topography.value)
                fd.append('topography', JSON.stringify(topography.value))
            if(derajatHistopatologi.value)
                fd.append('derajatHistopatologi', JSON.stringify(derajatHistopatologi.value))
            if(morphology.value)
                fd.append('morphology', JSON.stringify(morphology.value))
            if(icd10.value)
                fd.append('icd10', JSON.stringify(icd10.value))
            if(tanggalDijawab.value)
                fd.append('tanggalDijawab', tanggalDijawab.value)
            if(kesimpulan.value)
                fd.append('kesimpulan', kesimpulan.value)
            if(suratIzinPraktek.value)
                fd.append('suratIzinPraktek', suratIzinPraktek.value)
            if(uploadPengantar.value)
                fd.append('uploadPengantar', uploadPengantar.value)
            if(uploadPengantarName.value){
                let splitUploadPengantar = uploadPengantarName.value.split('/')
                fd.append('uploadPengantarName', splitUploadPengantar[splitUploadPengantar.length - 1])
            }

            fd.append('isDeleteUploadPengantar', isDeleteUploadPengantar.value)

            fd.append('id', props.id)
            if(catatanProcessing.value)
                fd.append('catatanProcessing', catatanProcessing.value)

            if(catatan.value)
                fd.append('catatan', catatan.value)

            if(anjuran.value)
                fd.append('anjuran', anjuran.value)
                
            if(catatanKonsultasi.value)
                fd.append('catatanKonsultasi', catatanKonsultasi.value)
            if(dokterKonsultasi.value)
                fd.append('dokterKonsultasi', dokterKonsultasi.value)

            if(setStatus)
                fd.append('statusPesanan', setStatus)
            
            $http({
                url: '/pemeriksaan/pemeriksaan/submit/'+props.action,
                data: fd,
                method: 'post',
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token'),
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => {
                    if(setStatus == 'Selesai')
                        router.push({name: 'pemeriksaanPemeriksaan'})
                    else
                        router.go(0)
                })
                .catch(err => {
                    isSubmit.value = false
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Submit Form`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const submitForm = async () => {
            isErrorAktif.value = false
            isAktifValidasi.value = true
            submit(statusPesanan.value)
        }

        const submitProses = async () => {
            isErrorAktif.value = true
            isAktifValidasi.value = true
            let statusPesananTemp = statusPesanan.value == 'Register' ? 'Processing' : statusPesanan.value == 'Processing' ? 'Diagnosa' : statusPesanan.value == 'Diagnosa' ? 'Selesai' : ''
            submit(statusPesananTemp)
        }

        const submitFormEmail = async () => {
            isErrorEmailPenerima.value = typeof emailPenerima.value == 'undefined' || emailPenerima.value == '' || emailPenerima.value == null ? false : null
            isErrorEmailSubject.value = typeof emailSubject.value == 'undefined' || emailSubject.value == '' || emailSubject.value == null ? false : null
            isErrorEmailPesan.value = typeof emailPesan.value == 'undefined' || emailPesan.value == '' || emailPesan.value == null ? false : null

            $http({
                url: '/mjmn/email/send',
                method: 'post',
                data: {
                    penerima: emailPenerima.value,
                    pengirim: emailPengirim.value,
                    subject: emailSubject.value,
                    pesan: emailPesan.value
                },
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    g.$bvToast.toast('Success', {
                        title: `Success Send Email`,
                        variant: 'success',
                        solid: true,
                    })
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Send Email`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const changeUploadPengantar = e => {
            if(e.target.files[0].size < 100000){
                isDeleteUploadPengantar.value = false
                let filePengantar = e.target.files[0]
                uploadPengantar.value = filePengantar

                if(filePengantar.type == 'application/pdf'){
                    gambarUploadPengantar.value = ''
                    pdfUploadPengantar.value = window.URL.createObjectURL(filePengantar)
                    
                    isShowUploadPdf.value = false
                }
                else{
                    var reader = new FileReader();
                        
                    reader.onload = (a) => gambarUploadPengantar.value = a.target.result
                    
                    reader.readAsDataURL(filePengantar);
                    isShowUploadPdf.value = false
                    fileTypePengantar.value = ''
                }
            }
            else{
                gambarUploadPengantar.value = ''
                pdfUploadPengantar.value = ''
            }
        }

        const clickToRegister = (statusTemp) => {
            backStatus.value = statusTemp
            context.refs['modal-register'].show()
        }

        const clickMoveToRegister = () => {
            isErrorAktif.value = false
            isAktifValidasi.value = false
            submit(backStatus.value)

            context.refs['modal-register'].hide()
        }

        const inputGelarKekeluargaan = () => {
            if(!jenisKelamin.value)
                jenisKelamin.value = gelarKekeluargaan.value == 'Tn' ? 'Laki-laki' : gelarKekeluargaan.value == 'Ny' || gelarKekeluargaan.value == 'Nn' ? 'Perempuan' : ''
        }

        const changeNamaNik = (text) => {
            $http({
                url: '/pemeriksaan/pemeriksaan/get-nama-nik',
                params: {
                    namaNik: text
                },
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    opsiNamaNik.value = [
                        {
                            data: res.data.data.dataPasien
                        }
                    ]
                })
                .catch(err => {
                    isSubmit.value = false
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Submit Form`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const selectedNamaNik = selected => {
            if(selected.item.value != '-- Tambah Pasien Baru --'){
                $http({
                    url: '/pemeriksaan/pemeriksaan/get-pasien',
                    method: 'post',
                    data: {
                        namaNik: selected.item.value
                    },
                    headers: {
                        Authorization: 'Bearer '+g.$cookies.get('token')
                    }
                })
                    .then(res => {
                        let {dataPasien, pasienVal, gelarKekeluargaanVal, namaVal, noBpjsVal, nikVal, tanggalLahirVal, jenisKelaminVal, alamatVal, kewarganegaraanVal, kodePosVal, hpTeleponVal, emailVal, agamaVal, statusPernikahanVal, beratBadanVal, tinggiBadanVal, provinsiVal, kotaKabupatenVal, kecamatanVal, kelurahanVal} = res.data.data

                        let {dataProvinsiVal, dataKotaKabupatenVal, dataKecamatanVal, dataKelurahanVal} = dataPasien
                        
                        pasien.value = pasienVal
                        gelarKekeluargaan.value = gelarKekeluargaanVal
                        nama.value = namaVal
                        nik.value = nikVal
                        tanggalLahir.value = tanggalLahirVal
                        tanggalLahir2.value = tanggalLahirVal
                        jenisKelamin.value = jenisKelaminVal
                        alamat.value = alamatVal
                        kewarganegaraan.value = kewarganegaraanVal
                        kodePos.value = kodePosVal
                        hpTelepon.value = hpTeleponVal
                        emailName.value = emailVal
                        status.value = statusPernikahanVal
                        agama.value = agamaVal
                        beratBadan.value = beratBadanVal
                        tinggiBadan.value = tinggiBadanVal
                        provinsi.value = provinsiVal
                        kotaKabupaten.value = kotaKabupatenVal
                        kecamatan.value = kecamatanVal
                        kelurahan.value = kelurahanVal
                        noBpjs.value = noBpjsVal

                        dataProvinsi.value = dataProvinsiVal
                        dataKotaKabupaten.value = dataKotaKabupatenVal
                        dataKecamatan.value = dataKecamatanVal
                        dataKelurahan.value = dataKelurahanVal
                    })
                    .catch(err => {
                        isSubmit.value = false
                        let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                        g.$bvToast.toast(showError || 'Something Wrong', {
                            title: `Error Submit Form`,
                            variant: 'danger',
                            solid: true,
                        })
                    })
            }
            else
                resetForm()
        }

        const inputProvinsi = () => {
            if(provinsi.value.text != '-- Pilih Opsi --'){
                $http({
                    url: '/mst/daerah/kota-kabupaten/get-data-kota-kabupaten',
                    params: {
                        provinsi: provinsi.value.value,
                        kotaKabupaten: kotaKabupaten.value.value,
                        halaman: 'form'
                    },
                    headers: {
                        Authorization: 'Bearer '+g.$cookies.get('token')
                    }
                })
                    .then(res => {
                        const {dataKotaKabupatenVal} = res.data.data
                        dataKotaKabupaten.value = dataKotaKabupatenVal

                        kotaKabupaten.value = {text: '-- Pilih Opsi --', value: null}
                    })
                    .catch(err => {
                        let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                        g.$bvToast.toast(showError || 'Something Wrong', {
                            title: `Error Kota / Kabupaten Table`,
                            variant: 'danger',
                            solid: true,
                        })
                    })
            }
            else{
                dataKotaKabupaten.value = []
            }
        }

        const inputKotaKabupaten = () => {
            if(kotaKabupaten.value.text != '-- Pilih Opsi --'){
                $http({
                    url: '/mst/daerah/kecamatan/get-data-kecamatan',
                    params: {
                        kotaKabupaten: kotaKabupaten.value.value,
                        kecamatan: kecamatan.value.value,
                        halaman: 'form'
                    },
                    headers: {
                        Authorization: 'Bearer '+g.$cookies.get('token')
                    }
                })
                    .then(res => {
                        const {dataKecamatanVal} = res.data.data
                        dataKecamatan.value = dataKecamatanVal

                        kecamatan.value = {text: '-- Pilih Opsi --', value: null}
                    })
                    .catch(err => {
                        let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                        g.$bvToast.toast(showError || 'Something Wrong', {
                            title: `Error Load Table`,
                            variant: 'danger',
                            solid: true,
                        })
                    })
            }
            else
                dataKotaKabupaten.value = []
        }

        const inputKecamatan = () => {
            if(kecamatan.value.text != '-- Pilih Opsi --'){
                $http({
                    url: '/mst/daerah/kelurahan/get-data-kelurahan',
                    params: {
                        kecamatan: kecamatan.value.value,
                        kelurahan: kelurahan.value.value,
                        halaman: 'form'
                    },
                    headers: {
                        Authorization: 'Bearer '+g.$cookies.get('token')
                    }
                })
                    .then(res => {
                        const {dataKelurahanVal} = res.data.data
                        dataKelurahan.value = dataKelurahanVal

                        kelurahan.value = {text: '-- Pilih Opsi --', value: null}
                    })
                    .catch(err => {
                        let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                        g.$bvToast.toast(showError || 'Something Wrong', {
                            title: `Error Load Table`,
                            variant: 'danger',
                            solid: true,
                        })
                    })
            }
            else
                dataKotaKabupaten.value = []
        }

        const searchKelurahan = (search) => {
            let kelurahanValue = kelurahan.value ? kelurahan.value.value : kelurahan.value;
            $http({
                url: '/mst/daerah/kelurahan/get-data-kelurahan',
                params: {
                    kecamatan: kecamatan.value.value,
                    kelurahan: kelurahanValue,
                    kelurahanLabel: search,
                    halaman: 'form'
                },
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    const {dataKelurahanVal} = res.data.data
                    dataKelurahan.value = dataKelurahanVal
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Load Table`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const searchKecamatan = (search) => {
            let kecamatanValue = kecamatan.value ? kecamatan.value.value : kecamatan.value;
            $http({
                url: '/mst/daerah/kecamatan/get-data-kecamatan',
                params: {
                    kotaKabupaten: kotaKabupaten.value.value,
                    kecamatan: kecamatanValue,
                    kecamatanLabel: search,
                    halaman: 'form'
                },
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    const {dataKecamatanVal} = res.data.data
                    dataKecamatan.value = dataKecamatanVal
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Load Table`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const searchIcd10 = (search) => {
            $http({
                url: '/mst/pemeriksaan/icd/get-data-icd-10',
                params: {
                    icd10: search
                },
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    const {dataIcd10Val} = res.data.data
                    dataIcd10.value = dataIcd10Val
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Load Table`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const inputOpsiTopography = () => {
            if(opsiTopography.value){
                $http({
                    url: '/mst/pemeriksaan/topography/get-data',
                    params: {
                        start: 0,
                        row: 10,
                        search: opsiTopography.value,
                    },
                    headers: {
                        Authorization: 'Bearer '+g.$cookies.get('token')
                    }
                })
                    .then(res => {
                        dataTopography.value = res.data.data
                    })
                    .catch(err => {
                        let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                        g.$bvToast.toast(showError || 'Something Wrong', {
                            title: `Error Data`,
                            variant: 'danger',
                            solid: true,
                        })
                    })
            }
            else
                dataTopography.value = []
        }

        const inputOpsiMikroskopik = () => {
            if(opsiMikroskopik.value){
                $http({
                    url: '/pemeriksaan/pemeriksaan/get-mikroskopik',
                    params: {
                        start: 0,
                        row: 5,
                        search: opsiMikroskopik.value
                    },
                    headers: {
                        Authorization: 'Bearer '+g.$cookies.get('token')
                    }
                })
                    .then(res => {
                        dataMikroskopik.value = res.data.data.dataMikroskopik
                    })
                    .catch(err => {
                        let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                        g.$bvToast.toast(showError || 'Something Wrong', {
                            title: `Error Data`,
                            variant: 'danger',
                            solid: true,
                        })
                    })
            }
            else
                dataMikroskopik.value = []
        }

        const inputOpsiMakroskopik = () => {
            if(opsiMakroskopik.value){
                $http({
                    url: '/pemeriksaan/pemeriksaan/get-makroskopik',
                    params: {
                        start: 0,
                        row: 5,
                        search: opsiMakroskopik.value
                    },
                    headers: {
                        Authorization: 'Bearer '+g.$cookies.get('token')
                    }
                })
                    .then(res => {
                        dataMakroskopik.value = res.data.data.dataMakroskopik
                    })
                    .catch(err => {
                        let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                        g.$bvToast.toast(showError || 'Something Wrong', {
                            title: `Error Data`,
                            variant: 'danger',
                            solid: true,
                        })
                    })
            }
            else
                dataMakroskopik.value = []
        }

        const inputOpsiMorphology = () => {
            if(opsiMorphology.value){
                $http({
                    url: '/mst/pemeriksaan/morphology/get-data',
                    params: {
                        start: 0,
                        row: 10,
                        search: opsiMorphology.value
                    },
                    headers: {
                        Authorization: 'Bearer '+g.$cookies.get('token')
                    }
                })
                    .then(res => {
                        dataMorphology.value = res.data.data
                    })
                    .catch(err => {
                        let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                        g.$bvToast.toast(showError || 'Something Wrong', {
                            title: `Error Data`,
                            variant: 'danger',
                            solid: true,
                        })
                    })
            }
            else
                dataMorphology.value = []
        }

        const clickOpsiMakroskopik = (item) => {
            const {text} = item
            makroskopik.value += text
        }
        
        const clickOpsiMikroskopik = (item) => {
            const {mikroskopik: mikroskopikVal, kesimpulan: kesimpulanVal} = item
            mikroskopik.value += mikroskopikVal
            kesimpulan.value += kesimpulanVal
        }

        const clickOpsiTopography = (item) => {
            const {label, penjelasan, kode} = item
            topography.value += `${label} (${kode})<br>${penjelasan}`
        }

        const clickOpsiMorphology = (item) => {
            const {label, penjelasan} = item
            morphology.value += `${label}<br>${penjelasan}`
        }

        const changeJenisPemeriksaan = () => {
            if(jenisPemeriksaan.value){
                if(decrypt(jenisPemeriksaan.value) == 3){
                    jenisKelamin.value = 'Laki-laki'
                    gelarKekeluargaan.value = 'Ny'
                }
                $http({
                    url: '/pemeriksaan/jenisPemeriksaan/get-kode/'+jenisPemeriksaan.value,
                    headers: {
                        Authorization: 'Bearer '+g.$cookies.get('token')
                    }
                })
                    .then(res => {
                        const {kodeJenisPemeriksaanVal, noSediaanVal, dataJenisPemeriksaanVal} = res.data.data
                        kodeJenisPemeriksaan.value = kodeJenisPemeriksaanVal
                        noSediaan.value = noSediaanVal
                        dataSediaan.value = dataJenisPemeriksaanVal.dataSediaanVal
                    })
                    .catch(err => {
                        let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                        g.$bvToast.toast(showError || 'Something Wrong', {
                            title: `Error Jenis Pemeriksaan Get Kode`,
                            variant: 'danger',
                            solid: true,
                        })
                    })
            }
            else
                kodeJenisPemeriksaan.value = null;
        }

        const clickFavorit = () => {
            isFillIcon.value = !isFillIcon.value
        }

        const clickKonsultasi = () => {
            $http({
                url: '/mjmn/pengguna/get-dokter/dokter-pemeriksa',
                params: {
                    idDokter: dokterKonsultasi.value,
                    disabledDokter: dokterPemeriksa.value
                },
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    if(res.data.success){
                        const {dokterVal, dataDokterVal} = res.data.data;
                        dokterKonsultasi.value = dokterVal
                        dataDokterKonsultasi.value = dataDokterVal
                        context.refs['modal-konsultasi'].show()
                    }
                })
                .catch(err => {
                    isSubmit.value = false
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Submit Form`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const clickMoveToKonsultasi = () => {
            isErrorAktif.value = false
            isAktifValidasi.value = false
            submit(statusPesanan.value)

            context.refs['modal-register'].hide()
        }

        const onContext = ctx => {
            const tempTanggalLahir = moment(ctx.selectedYMD, 'YYYY-MM-DD')

            if(tempTanggalLahir.format('DD-MM-YYYY') == 'Invalid date'){
                tanggalLahir.value = ''
                umurTahun.value = ''
                umurBulan.value = ''
                umurHari.value = ''
            }
            else{
                tanggalLahir.value = tempTanggalLahir.format('DD-MM-YYYY')
                umurTahun.value = now.diff(tempTanggalLahir, 'years')
                tempTanggalLahir.add(umurTahun.value, 'years')
                
                umurBulan.value = now.diff(tempTanggalLahir, 'months')
                tempTanggalLahir.add(umurBulan.value, 'months')

                umurHari.value = now.diff(tempTanggalLahir, 'days')
            }
        }

        const onContextTanggalDijawab = ctx => {
            const tempTanggalDijawab = moment(ctx.selectedYMD, 'YYYY-MM-DD').format('DD-MM-YYYY')
            tanggalDijawab.value = tempTanggalDijawab == 'Invalid date' ? '' : tempTanggalDijawab
        }

        const changeTanggalLahir = () => {
            const tempTanggalLahir = moment(tanggalLahir.value, 'DD-MM-YYYY', true)

            if(tempTanggalLahir.format('YYYY-MM-DD') == 'Invalid date'){
                tanggalLahir2.value = ''
                umurTahun.value = ''
                umurBulan.value = ''
                umurHari.value = ''
            }
            else{
                tanggalLahir2.value = tempTanggalLahir.format('YYYY-MM-DD')
                umurTahun.value = now.diff(tempTanggalLahir, 'years')
                tempTanggalLahir.add(umurTahun.value, 'years')
                
                umurBulan.value = now.diff(tempTanggalLahir, 'months')
                tempTanggalLahir.add(umurBulan.value, 'months')

                umurHari.value = now.diff(tempTanggalLahir, 'days')
            }
        }

        const changeTanggalDijawab = () => {
            const tempTanggalDijawab = moment(tanggalDijawab.value, 'DD-MM-YYYY', true).format('YYYY-MM-DD')
            tanggalDijawab2.value = tempTanggalDijawab == 'Invalid date' ? '' : tempTanggalDijawab
        }

        const generatePDF = async (id) => {
            await context.refs.generateHasil.generatePDF(id, {}, 'show')
        }

        const validateState = (item) => {
            const { $dirty, $error } = item
            return !$dirty ? null : $error ? false : null
        }

        const resetForm = () => {
            pasien.value = null
            gelarKekeluargaan.value = null
            nama.value = ''
            nik.value = ''
            noBpjs.value = ''
            tanggalLahir.value = ''
            tanggalLahir2.value = ''
            jenisKelamin.value = ''
            alamat.value = ''
            kewarganegaraan.value = 'WNI'
            provinsi.value = {text: '-- Pilih Opsi --', value: null}
            kotaKabupaten.value = {text: '-- Pilih Opsi --', value: null}
            kecamatan.value = {text: '-- Pilih Opsi --', value: null}
            kelurahan.value = {text: '-- Pilih Opsi --', value: null}
            kodePos.value = ''
            hpTelepon.value = ''
            emailName.value = ''
            status.value = ''
            agama.value = {text: '-- Pilih Opsi --', value: null}
            beratBadan.value = ''
            tinggiBadan.value = ''
            
            jenisPemeriksaan.value = null
            kodeJenisPemeriksaan.value = null
            noSediaan.value = '0001'
            rsKlinik.value = null
            dokterPengirim.value = null
            noRekamMedis.value = ''
            pembiayaan.value = {text: '-- Pilih Opsi --', value: null}
            biaya.value = ''
            isBayar.value = []
            isTagih.value = []
            isProcessing.value = []
            lokasiAsalJaringan.value = []
            jaringan.value = null
            sediaan.value = null
            jumlahWadah.value = ''
            jumlahJaringan.value = ''
            dokterPemeriksa.value = null
            isSpesimenDiterima.value = []
            waktuOperasi.value = ''
            waktuFiksasi.value = ''
            fiksasi.value = ''
            isVolFiksasi10x.value = []
            derajatHistopatologi.value = ''
        }

        const changeIntOnly = (evt) => {
            var ASCIICode = (evt.which) ? evt.which : evt.keyCode
            if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
                evt.preventDefault()
        }

        const showUploadPengantar = () => {
            if(uploadPengantar.value.type == 'application/pdf' || fileTypePengantar.value == 'pdf'){
                isShowUploadPdf.value = !isShowUploadPdf.value
                setTimeout(() => {
                    pdfobject.embed(pdfUploadPengantar.value, '#pengantarPdf')
                }, 200);
            }
            else{
                gambarModal.value = gambarUploadPengantar.value
                isShowModalGambar.value = true
            }
        }

        const hapusUploadPengantar = i => {
            gambarUploadPengantar.value = ''
            fileTypePengantar.value = ''
            uploadPengantar.value = []
            isDeleteUploadPengantar.value = true
            isShowUploadPdf.value = false
            pdfUploadPengantar.value = ''
        }

        watch(statusPesanan, () => {
            if(statusPesanan.value != 'Draf' && statusPesanan.value != 'Batal'){
                g.$bvToast.toast(`${gelarKekeluargaan.value} ${nama.value}`, {
                    id: 'infoSpesimenToast',
                    title: `${kodeSediaan.value} | ${moment(tanggalDiterima.value, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY')}`,
                    variant: jenisKelamin.value == 'Laki-laki' ? 'info' : 'success',
                    noAutoHide: true,
                    solid: true,
                    toaster: 'b-toaster-top-center'
                })
            }
        })

        onUnmounted( () => {
            g.$bvToast.hide('infoSpesimenToast')
        })

        return {
            v$, $externalResults,

            nama, nik, tanggalLahir, tanggalLahir2, jenisKelamin, alamat, namaNik, opsiNamaNik, kotaKabupaten, kewarganegaraan, provinsi, kodePos, hpTelepon, emailName, status, agama, beratBadan, tinggiBadan, opsiKeteranganKlinis, opsiDiagnosaKlinis, opsiRiwayatLabPa, keteranganKlinis, diagnosaKlinis, riwayatLabPa, gelarKekeluargaan, max, jenisPemeriksaan, rsKlinik, noRekamMedis, lokasiAsalJaringan, dataJaringanDidapat, jaringan, dataSediaan, sediaan, jumlahWadah, jumlahJaringan, dataPembiayaan, dataKelurahan, dataKecamatan, kecamatan, kelurahan, inputKecamatan, dataAgama, searchKelurahan, searchKecamatan, statusPesanan, dokterPemeriksa, dataDokterPemeriksa, peran, tanggalDiterima, noSediaan, pembiayaan, opsiMikroskopik, opsiMakroskopik, dataMakroskopik, dataMikroskopik, makroskopik, mikroskopik, noBpjs, waktuOperasi, waktuFiksasi, fiksasi, dataFiksasi, backStatus, catatanProcessing, opsiTopography, dataTopography, topography, derajatHistopatologi, opsiMorphology, morphology, dataMorphology, icd10, dataIcd10, tanggalDijawab, tanggalDijawab2, emailPengirim, emailPenerima, emailSubject, emailPesan, uploadPengantar, suratIzinPraktek, gambarUploadPengantar, gambarModal, uploadPengantarName, catatan, anjuran, pdfUploadPengantar, catatanKonsultasi, dokterKonsultasi,
            
            dataGelarKekeluargaan, dataProvinsi, dataKeteranganKlinis, dataDiagnosaKlinis, dataRiwayatLabPa, dataKotaKabupaten, dataKewarganegaraan, dataJenisPemeriksaan, dataDerajatHistopatologi, dataSuratIzinPraktek, dataRsKlinik, dataDokterPengirim, dokterPengirim, dataLokasiAsalJaringan, dataDokterKonsultasi,

            isSubmit, isBayar, isTagih, isProcessing, isSpesimenDiterima, isFillIcon, isVolFiksasi10x, isSubmitEmail, isErrorEmailPenerima, isErrorEmailSubject, isErrorEmailPesan, isShowModalGambar, isShowUploadPdf, isKonsultasi, isErrorKonsultasiDokter, inputGelarKekeluargaan,

            resetForm, validateState, submitForm, changeNamaNik, selectedNamaNik, inputProvinsi, inputKotaKabupaten, changeJenisPemeriksaan, clickFavorit, clickKonsultasi, onContext, changeTanggalLahir, submitProses, inputOpsiMakroskopik, inputOpsiMikroskopik, clickOpsiMakroskopik, clickOpsiMikroskopik, clickToRegister, clickMoveToRegister, inputOpsiTopography, inputOpsiMorphology, changeTanggalDijawab, onContextTanggalDijawab, clickOpsiTopography, clickOpsiMorphology, submitFormEmail, generatePDF, changeIntOnly, searchIcd10, changeUploadPengantar, showUploadPengantar, hapusUploadPengantar, submit, clickMoveToKonsultasi,

            kodeSediaan, umurHari, umurBulan, umurTahun,

            pNoSediaan, pNik, pNama, pNoBpjs, pUmurTglLahir, pNoRekamMedis, pJenisKelamin, pAlamat, pTanggalDiterima, pJamOperasi, pTanggalDijawab, pJamFiksasi, pSediaan, pJumlahJaringan, pLokasiAsalJaringan, pFiksasi, pTopography, pIsVolFiksasi10x, pDiagnosaKlinis, pKeteranganKlinis, pRiwayatLabPa, pMakroskopik, pMikroskopik, pKesimpulan, pDerajatHistopatologi, pMorphology, pIcdO, pDokterPemeriksa, labelPdf1, labelPdf2, labelPdf3, labelPdf4, valuePdf1, valuePdf2, valuePdf3, valuePdf4, pJenisPemeriksaan, pCatatan, pAnjuran
        }
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    data(){
        return {
            editorOptionKeteranganKlinis: {
                modules: {
                    toolbar: '#keteranganKlinis',
                },
            },
            editorOptionDiagnosaKlinis: {
                modules: {
                    toolbar: '#diagnosaKlinis',
                },
            },
            editorOptionRiwayatLabPa: {
                modules: {
                    toolbar: '#riwayatLabPa',
                },
            },
            editorOptionMakroskopik: {
                modules: {
                    toolbar: '#makroskopik',
                },
            },
            editorOptionMikroskopik: {
                modules: {
                    toolbar: '#mikroskopik',
                },
            },
            editorOptionKesimpulan: {
                modules: {
                    toolbar: '#kesimpulan',    
                },
            },
            editorOptionCatatan: {
                modules: {
                    toolbar: '#catatan',    
                },
            },
            editorOptionAnjuran: {
                modules: {
                    toolbar: '#anjuran',    
                },
            },
        }
    }
}
</script>

<style lang="scss" scope>
@import "@core/scss/vue/libs/vue-autosuggest.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.pdfobject-container {
  height: 80rem;
  border: 1rem solid rgba(0, 0, 0, 0.1);
}
.gambarku:hover {
  opacity: 0.7;
}

.topLeft {
  position: absolute;
  top: 2px;
  left: 16px;
  font-size: 18px;
  z-index: 100;
}

.ql-editor {
  /* font-size: 1.5em; */
  font-size: 15px;
}

#infoSpesimenToast {
  font-size: 15px;
}
</style>